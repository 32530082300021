import { Box, Flex, Text } from "@chakra-ui/react";
import { colors } from "../../../../styles/js/colors";
import styles from "./stat-label.module.scss";

interface StatLabelProps {
  stat: string;
  description: string;
  className?: string;
}

export const StatLabel = ({ stat, description, className }: StatLabelProps) => {
  return (
    <Flex flexDir={"column"} justify={"center"}>
      <Flex flexDir={"column"}>
        <Box w={"96px"} h={"4px"} bgColor={colors.lime}></Box>
        <Text
          textStyle={{ base: "stat" }}
          paddingTop={"28px"}
          className={`${styles.textStyles} ${className}`}
        >
          {stat}
        </Text>
      </Flex>
      <Text
        className={styles.description}
        textStyle={{ base: "paragraphMDRegular" }}
      >
        {description}
      </Text>
    </Flex>
  );
};
