import { useEffect, useState } from "react";
import styles from "./keep-reading.module.scss";
import { Box, Flex, Text, useColorMode } from "@chakra-ui/react";
import { colors } from "../../../styles/js/colors";

import { getNewsInsights, getProjects, getServices } from "../../../lib/api/posts";

import { ChevronRightIcon } from "@chakra-ui/icons";
import TextButtonArrowIcon from "../../atoms/buttons/text-button-arrow-icon/text-button-arrow-icon";
import { BlogCard } from "../../molecules/blog-card/blog-card";
import { ProjectCardDataFormater } from "../../../lib/projectCardsDataFormater";
import Link from "next/link";
import { NewsAndInsightsCardsDataFormater } from "../../../lib/newsAndInsightsCardsDataFormater";
import { ServiceCardDataFormatter } from "../../../lib/ServicesCardsDataFormatter"

interface KeepReadingProps {
  post: {
    blogCards: [
      {
        __typename: string;
        title: string;
      }
    ];
    title: string;
    goTo: string;
    goToPath: string;
  };
}

export const KeepReading = ({ post }: KeepReadingProps) => {
  const { colorMode } = useColorMode();

  const [blogCards, setBlogCards] = useState([]);
  //simplify the prop data to an array
  const blogsToShow = post?.blogCards?.map((card) => {
    return card.title;
  });
  useEffect(() => {
    // fetches the blogs
    if (post.blogCards[0].__typename === "NewsInsights") {
      getNewsInsights().then((data) => {
        //formats the fetched data
        const tempBlogCards = NewsAndInsightsCardsDataFormater(data.data);
        const filteredBlogCards = [];
        tempBlogCards.blogCards.filter((blogCard) => {
          blogsToShow.includes(blogCard.heroText) &&
            filteredBlogCards.push(blogCard);
        });
        setBlogCards(filteredBlogCards);
      });
    } else if (post.blogCards[0].__typename === 'Project') {
      getProjects().then((data) => {
        //formats the fetched data
        const tempBlogCards = ProjectCardDataFormater(data.data);
        const filteredBlogCards = [];
        tempBlogCards.blogCards.filter((blogCard) => {
          blogsToShow.includes(blogCard.heroText) &&
            filteredBlogCards.push(blogCard);
        });
        setBlogCards(filteredBlogCards);
      });
    } else if (post.blogCards[0].__typename === 'Service') {
      getServices().then((data) => {
        const tempBlogCards = ServiceCardDataFormatter(data.data);
        const filteredBlogCards = [];
        tempBlogCards.blogCards.filter((blogCard) => {
          blogsToShow.includes(blogCard.title) &&
            filteredBlogCards.push(blogCard)
        });
        setBlogCards(filteredBlogCards);
      });
    }
  }, []);

  return (
    <Box
      // backgroundColor={colorMode === "light" ? colors.gray[10] : colors.navy}
      backgroundColor={colors.gray[10]}
    >
      <Flex
        className={`${styles.contentWrapper} wrapper`}
        padding={"128px 64px 96px 64px"}
        flexDir="column"
      >
        <Flex
          className={styles.headerWrapper}
          justifyContent={"space-between"}
          alignItems="center"
          width="100%"
        >
          <Text textStyle={{ base: "heading5Medium" }} as='h3'>{post.title}</Text>
          <Link href={post.goToPath ? post.goToPath : "/news-insights"}>
            <a>
              <TextButtonArrowIcon
                //this variant adds animation to the text
                variant="keepReading"
                textBaseStyle="heading8Medium"
                text={post.goTo}
                color={colorMode === "light" ? colors.navy : "white"}
                icon={<ChevronRightIcon />}
                onClick={undefined}
                leftIcon={false}
                rightIcon={true}
                keepReading={true}
              />
            </a>
          </Link>
        </Flex>

        <Box
          className={styles.cardsGrid}
          display={"grid"}
          gridTemplateColumns="1fr 1fr"
          gap={"56px"}
          padding="44px 0px"
          alignSelf={"center"}
          justifySelf={"center"}
        >
          {blogCards &&
            blogCards?.map((card) => {
              return <BlogCard key={card.heroText} cardData={card}></BlogCard>;
            })}
        </Box>
      </Flex>
    </Box>
  );
};
