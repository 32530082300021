/** @format */

import {Flex, Text} from '@chakra-ui/react';
import Image from 'next/image';
import styles from './images.module.scss';
interface imagesProps {
	post: {
		description?: string;
		displayFullWidth?: boolean;
		postHeaderImage?: boolean;
		image: {
			link: string;
			altText: string;
			mediaDetails: {
				width: number;
				height: number;
			};
		};
	};
}
export const Images = ({post}: imagesProps) => {
	return (
		<Flex className={post.postHeaderImage ? styles.imgBackground : ''}>
			<Flex className={`wrapper`} flexDirection={'column'}>
				<Image
					src={post.image?.link}
					alt={post?.image?.altText || ''}
					className={styles.image}
					height={post.image.mediaDetails.height}
					width={post.image.mediaDetails.width}
				/>
				{post.description && (
					<Text paddingTop={'16px'} textStyle={{base: 'paragraphSMRegular'}}>
						{post.description}
					</Text>
				)}
			</Flex>
		</Flex>
	);
};
