import { MouseEventHandler } from "react";
import { Box, Button, Flex, Text } from "@chakra-ui/react";

interface DefaultButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  variant?: string;
  text: string;
  textColor?: string;
  rightIcon: boolean;
  leftIcon: boolean;
  className?: string;
  icon: any;
}
const CustomIconButton = ({
  variant,
  onClick,
  text,
  textColor,
  rightIcon,
  leftIcon,
  className,
  icon,
}: DefaultButtonProps) => {
  return (
    <Button
      className={className}
      display={"flex"}
      variant={variant ? variant : "primary"}
      alignItems={"center"}
      justifyContent={"center"}
      onClick={onClick}
    >
      <Flex justify="center">
        {leftIcon && (
          <Box width={"24px"} height={"24px"}>
            {icon}
          </Box>
        )}
        <Text color={textColor}>{text}</Text>

        {rightIcon && (
          <Box marginLeft={"8px"} width={"24px"} height={"24px"}>
            {icon}
          </Box>
        )}
      </Flex>
    </Button>
  );
};
export default CustomIconButton;
