import {mapLink} from '../../../utils';

export const servicesOverviewMapping = (data: any) => {
	if (!data) return null;
	const {
		tagline = '',
		headerText = '',
		description = '<p></p>',
		images = [],
		brandingList = [],
		readMoreLinkText = null,
		partialbackground = null,
		brandColor = null,
	} = data;

	let backgroundColor = null;
	if (partialbackground) {
		const {
			backgroundtype = null,
			customcolour = null,
			themebackgroundcolor = null,
		} = partialbackground;

		backgroundColor =
			backgroundtype === 'customColor' ? customcolour : themebackgroundcolor;
		if (backgroundtype === null) backgroundColor = null;
	}

	let brandColour = null;
	if (brandColor.partialbackground) {
		const {
			backgroundtype = null,
			customcolour = null,
			themebackgroundcolor = null,
		} = brandColor.partialbackground;

		brandColour =
			backgroundtype === 'customColor' ? customcolour : themebackgroundcolor;
		if (backgroundtype === null) backgroundColor = null;
	}

	return {
		...data,
		backgroundColor,
		brandColour,
		description: {
			__html: description,
		},
		readMoreLinkText: mapLink(readMoreLinkText),
	};
};
