/** @format */

import styles from './stat-and-image.module.scss';
import {Box, Flex, Image, useColorMode} from '@chakra-ui/react';
import {StatLabel} from '../../atoms/labels/stat-label/stat-label';
import {colors} from '../../../styles/js/colors';

interface StatAndImageProps {
	post: {
		stat: string;
		description: string;
		image: {
			altText: string;
			link: string;
		};
	};
}

export const StatAndImage = ({post}: StatAndImageProps) => {
	const {colorMode} = useColorMode();
	return (
		<Flex
			className={`wrapper ${styles.wrapper}`}
			h={'auto'}
			justifyContent={'space-around'}>
			<Flex
				className={styles.statBox}
				flexDir={'column'}
				justifyContent={'center'}
				alignItems='center'
				width={'628px'}
				height={'auto'}
				backgroundColor={colorMode === 'light' ? colors.gray[10] : ''}>
				<Box padding={'0 64px'} className={styles.statWrapper}>
					<StatLabel
						stat={post.stat}
						description={post.description}
						className={styles.statLabel}
					/>
				</Box>
			</Flex>

			<Box
				w={'628px'}
				maxHeight={'628px'}
				className={styles.imageWrapper}
				style={{aspectRatio: 1 / 1}}>
				<Image
					src={post?.image?.link}
					alt={post?.image?.altText || 'stat-and-image'}
					w={'100%'}
					h={'100%'}
					objectFit={'cover'}
				/>
			</Box>
		</Flex>
	);
};
