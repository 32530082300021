/** @format */

import styles from './text-and-image.module.scss';
import {Box, Flex, Image} from '@chakra-ui/react';

interface TextAndImageProps {
	post: {
		wysiwyg: string;
		image: {
			link: string;
			altText: string;
		};
	};
}

export const TextAndImage = ({post}: TextAndImageProps) => {
	return (
		<Flex
			className={`wrapper ${styles.wrapper}`}
			h={'auto'}
			justifyContent={'space-around'}
			gap={'56px'}>
			<Flex
				flexDir={'column'}
				justifyContent={'center'}
				width={'628px'}
				height={'auto'}
				className={styles.textStyles}
				dangerouslySetInnerHTML={{__html: post.wysiwyg}}></Flex>
			<Box
				w={'628px'}
				maxHeight={'628px'}
				className={styles.imageWrapper}
				style={{aspectRatio: 1 / 1}}>
				<Image
					alt={post.image?.altText || 'text-and-image'}
					src={post.image?.link}
					w={'100%'}
					h={'100%'}
					objectFit={'cover'}
				/>
			</Box>
		</Flex>
	);
};
