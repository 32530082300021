import React from "react";
import { Box } from "@chakra-ui/react";

const LinkedinIcon = () => {
  return (
    <Box
      width={"48px"}
      height={"48px"}
      borderRadius={"24px"}
      border={"2px solid white"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      cursor={"pointer"}
      _hover={{
        background: "white",
        path: {
          fill: "black",
        },
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.37214 23.9995H0.396429V7.97625H5.37214V23.9995ZM2.88161 5.79054C1.29054 5.79054 0 4.47268 0 2.88161C2.37147e-08 1.29014 1.29014 0 2.88161 0C4.47307 0 5.76321 1.29014 5.76321 2.88161C5.76321 4.47268 4.47214 5.79054 2.88161 5.79054ZM23.9946 23.9995H19.0296V16.1995C19.0296 14.3405 18.9921 11.9566 16.4427 11.9566C13.8557 11.9566 13.4593 13.9763 13.4593 16.0655V23.9995H8.48893V7.97625H13.2611V10.162H13.3307C13.995 8.90304 15.6177 7.57446 18.0386 7.57446C23.0743 7.57446 24 10.8905 24 15.1977V23.9995H23.9946Z"
          fill="white"
        />
      </svg>
    </Box>
  );
};
export default LinkedinIcon;
