import { Flex } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  pageInfo,
  selectBlogCards,
  setBlogCards,
  updatePageInfo,
} from "../../../redux/slices/blogCardsSlice";
import CTAButton from "../../atoms/buttons/cta-button/cta-button";
import PlusIcon from "../../../icons/plus-icon";

import style from "./cta-style.module.scss";
import { selectedFiltersState } from "../../../redux/slices/filtersSlice";
import { ProjectCardDataFormater } from "../../../lib/projectCardsDataFormater";
import { getBlogs } from "../../../lib/api/blogs";
import { useRouter } from "next/router";
import { BlogCardsProps } from "../../../classes/blog-cards";
import { NewsAndInsightsCardsDataFormater } from "../../../lib/newsAndInsightsCardsDataFormater";
import { FilterProps } from "../../../classes/filter";

interface ViewMoreProjectsProps {
  post: {
    buttonText: string;
  };
}

export const ViewMoreProjects = ({ post }: ViewMoreProjectsProps) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const tempPageInfo = useSelector(pageInfo);
  const selectedFilters: FilterProps = useSelector(selectedFiltersState);
  const oldData = useSelector(selectBlogCards);

  //update the redux states
  const handleViewMoreProjectsClick = async () => {
    let formatedData = new BlogCardsProps();
    let pagePosts = await getBlogs(router.asPath, tempPageInfo.endCursor);
    if (pagePosts?.allNewsInsights?.edges) {
      formatedData = NewsAndInsightsCardsDataFormater(pagePosts);
      dispatch(updatePageInfo(pagePosts?.allNewsInsights?.pageInfo));
    } else if (pagePosts?.projects?.edges) {
      formatedData = ProjectCardDataFormater(pagePosts);
      dispatch(updatePageInfo(pagePosts?.projects?.pageInfo));
    }
    let tempOldData = [...oldData] as typeof formatedData.blogCards;
    formatedData.blogCards.forEach((card) => {
      tempOldData.push(card);
    });

    formatedData.blogCards = tempOldData;
    dispatch(
      setBlogCards({ post: formatedData, selectedFilters: selectedFilters })
    );
  };
  //Show this button only if there are more than 10 blog posts and if there are more blogs to show
  return (
    // tempPageInfo.hasNextPage && (
    false ? (
      <Flex
        padding="84px 64px"
        justifyContent="center"
        alignItems={"center"}
        className={style.ctaViewMoreProjectsButton}
      >
        <CTAButton
          onClick={handleViewMoreProjectsClick}
          text={post.buttonText}
          rightIcon={true}
          leftIcon={false}
          icon={<PlusIcon />}
        ></CTAButton>
      </Flex>
    ) : (
      <></>
    )
  );
};
