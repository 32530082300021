//@ts-nocheck
import styles from "./services.module.scss";
import { colors } from "../../../styles/js/colors";
import { Box, Flex, Text } from "@chakra-ui/react";
import HalfCirlce from "../../../icons/half-circle";
import { ServicesCardGrid } from "../../molecules/services-cards-grid/services-card-grid";

interface ServicesProps {
  post: {
    serviceTitle: string;
    serviceHeroText: string;
    serviceDescription: string;
    cardLink: [
      {
        cardLinkTitle: string;
        svg: {
          link: string;
        };
      }
    ];
    designColor: string;
  };
}

export const Services = ({ post }: ServicesProps) => {
  return (
    <Flex className={styles.wrapper}>
      <Flex
        className={`${styles.contentWrapper} wrapper`}
        flexDir={"column"}
        padding={" 128px 128px 128px 178px"}
      >
        <Box maxW={"628px"}>
          <Text textStyle={"heading8Medium"} color={colors.spruce}>
            {post.serviceTitle}
          </Text>
          <Text
            className={styles.heroText}
            textStyle={"heading3Medium"}
            padding={"32px 0px 32px 0px"}
            as='h3'
          >
            {post.serviceHeroText}
          </Text>
          <Text textStyle={"paragraphMDRegular"} paddingBottom={"32px"}>
            {post.serviceDescription}
          </Text>
          <ServicesCardGrid cards={post.cardLink} />
        </Box>
      </Flex>
      <Box
        className={styles.circle}
        position={"absolute"}
        right={"224px"}
        marginTop={"250px"}
      >
        <HalfCirlce fill={post.designColor} width="100%" height="100%" />
      </Box>
    </Flex>
  );
};
