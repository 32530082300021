import { Flex, Text } from "@chakra-ui/react";

interface ExpertiseProps {
  post: {
    expertise: [
      {
        expertiseList: {
          expertiseTitle: string;
          listItem: [{ listItemName: string }];
        };
      }
    ];
  };
}

export const Expertise = ({ post }: ExpertiseProps) => {
  return (
    <Flex
      margin={"0 20%"}
      paddingBottom={"96px"}
      justifyContent={"space-around"}
    >
      {post.expertise?.map((expertise) => {
        return (
          <Flex flexDir={"column"} key={expertise.expertiseList.expertiseTitle}>
            <Text color="black">{expertise.expertiseList.expertiseTitle}</Text>
            {expertise.expertiseList.listItem?.map((listItem) => {
              return (
                <Text key={listItem.listItemName}>{listItem.listItemName}</Text>
              );
            })}
          </Flex>
        );
      })}
    </Flex>
  );
};
