export enum patternsEnum {
	Header = 'Header',
	SectionHeader = 'SectionHeader',
	MainMenu = 'OptionsPageMainMenu',
	HomePageHeader = 'HomePageHeader',
	ExploreButton = 'ExploreButton',
	HeaderWithCalendar = 'HeaderWithCalendar',
	TitleWithImage = 'TitleWithImage',
	ProjectGrid = 'ProjectGrid',
	PostHeader = 'PostHeader',
	LocationGrid = 'LocationGrid',
	FilterItems = 'FilterItems',
	ScrollableTabs = 'ScrollableTabs',
	BlogCardsGrid = 'BlogGrid',
	CTAButton = 'CtaViewMoreProjects',
	CTABanner = 'CtaBanner',
	Footer = 'OptionsPageFooter',
	PostText = 'PostTextBlock',
	Image = 'Image',
	BlogAuthor = 'BlogAuthor',
	KeepReading = 'KeepReading',
	WYSIWYG = 'Wysiwyg',
	WYSIWYGVideo = 'Wysiwygvideo',
	TextAndImage = 'TextImage',
	StatAndImage = 'StatImage',
	StatsGrid = 'StatGrid',
	ImagesGrid = 'ImagesGrid',
	Expertise = 'Expertise',
	Testimonial = 'Testimonial',
	CustomCards = 'CustomCards',
	Highlights = 'Highlights',
	Services = 'Services',
	ServicesGroup = 'ServicesGroup',
	ServiceHeader = 'ServiceHeader',
	RelatedPosts = 'RelatedPosts',
	ServicesOverview = 'ServicesOverview',
}
