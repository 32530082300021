import {useState} from 'react';
import styles from './header.module.scss';
import {Box, Flex, Text, useColorMode, Button, Link} from '@chakra-ui/react';
import {ArrowForwardIcon} from '@chakra-ui/icons';
//custom components
import LineLabel from '../../atoms/labels/label-with-line/line-label';
import MainMenu from '../main-menu/main-menu';
//svg's
import {BulbLogo} from '../../molecules/main-menu-nav/bulb-logo';
import CustomIconButton from '../../atoms/buttons/icon-button/icon-button';
import {fonts} from '../../../styles/js/fonts';
import {HeaderHeroText} from '../../../interfaces';
import {linkResolver} from '../../../lib/linkResolver';

interface HeaderProps {
	data: {
		minH?: string;
		paddingForText?: string;
		pageTitle: string;
		headerHeroText: HeaderHeroText;
		textUnderTitle: Array<{paragraph: string}>;
		text: string;
		darkBackground: boolean;
		header: boolean;
		fontSize: string;
		buttonText: string;
		buttonLink: {
			target: string;
			title: string;
			url: string;
		};
	};
}
const Header = ({data}: HeaderProps) => {
	const {colorMode} = useColorMode();
	const [showMainMenu, setShowMainMenu] = useState(false);
	function _handleOnClick() {
		setShowMainMenu(!showMainMenu);
	}

	const isBrowser = typeof window !== 'undefined';

	return (
		<Box backgroundColor={data.darkBackground ? '#121D33' : ''}>
			{showMainMenu && <MainMenu onClick={_handleOnClick} />}

			<Box
				className={styles.headerWrapper}
				// backgroundColor={data.darkBackground ? "#121D33" : ""}
				// width={"100%"}
				minH={data.minH ? data.minH : '50vh'}>
				{data.header ? (
					<Box
						className={`${styles.headerComponent} wrapper`}
						display={'flex'}
						alignItems={'center'}
						justifyContent={'space-between'}>
						<Link href={'/'}>
							{colorMode === 'dark' ? (
								<Box cursor={'pointer'}>
									<BulbLogo fill='white' />
								</Box>
							) : (
								<Box cursor={'pointer'}>
									<BulbLogo fill={data.darkBackground ? 'white' : ''} />
								</Box>
							)}
						</Link>
						<CustomIconButton
							variant={data.darkBackground ? 'transparent' : 'border'}
							text={'Menu'}
							rightIcon={true}
							leftIcon={false}
							icon={
								<div
									className={`${styles.burger} ${
										data.darkBackground ?? styles.darkBurger
									}`}></div>
							}
							onClick={_handleOnClick}
							className={styles.button}
						/>
					</Box>
				) : null}
				<Flex
					flexDirection='column'
					className={styles.contentWrapper}
					padding={`${
						data.paddingForText
							? `104px calc(20vw - 64px) 0 calc(20vw - 64px)`
							: '104px 64px 64px 178px'
					}`}>
					<Box
						paddingBottom='32px'
						className={data.paddingForText ? styles.centerHeader : ''}>
						{data.header ? (
							data.pageTitle ? (
								<LineLabel
									color={data.darkBackground ? '#ffffff' : ''}
									labelText={data.pageTitle}
								/>
							) : (
								''
							)
						) : data.pageTitle ? (
							<Flex
								alignItems='center'
								justifyContent='center'
								fontWeight='500'
								fontSize='20px'
								lineHeight='32px'
								width='fit-content'>
								<Text color='#59D8C9'>{data.pageTitle}</Text>
							</Flex>
						) : null}
					</Box>

					<Text
						className={`${styles.headerText} ${
							data.paddingForText ? styles.centerHeader : ''
						}`}
						fontSize={
							data.headerHeroText.fontSize
								? data.headerHeroText.fontSize
								: '80px'
						}
						textColor={data.darkBackground ? '#ffffff' : ''}
						lineHeight={
							data.headerHeroText.fontSize
								? `${Number(data.headerHeroText.fontSize) + 8}px`
								: '88px'
						}
						letterSpacing={data.header ? '-1.92px' : '-0.96px'}
						fontWeight={'500'}
						as={'h1'}>
						{data.headerHeroText?.text}
					</Text>
					{data.textUnderTitle
						? data.textUnderTitle?.map(text => (
								<Text
									fontSize='24px'
									key={Math.random()}
									className={styles.textUnderTitle}
									fontFamily={fonts.DMSansMedium}
									fontWeight='400'
									textColor={data.darkBackground ? '#ffffff' : ''}
									lineHeight='36px'
									letterSpacing='-0.24px'
									maxWidth='856px'
									marginBottom='32px'
									marginTop='32px'>
									{text.paragraph}
								</Text>
						  ))
						: null}
					{data.buttonText ? (
						// this is intended to be changed to an acf field input for the button link in the future
						<Link
							href={
								data?.buttonLink?.url
									? linkResolver(data?.buttonLink?.url)
									: isBrowser && window?.location?.pathname === '/about'
									? '/services'
									: '/about'
							}>
							<Button
								variant='outline'
								colorScheme='black'
								className={styles.buttonLink}
								marginTop='12px'
								padding='12px, 24px, 12px, 24px'
								maxWidth='228px'
								textColor={data.darkBackground ? '#ffffff' : ''}>
								{data.buttonText}{' '}
								<ArrowForwardIcon fontSize='25px' marginLeft='5px' />
							</Button>
						</Link>
					) : null}
				</Flex>
			</Box>
		</Box>
	);
};
export default Header;
