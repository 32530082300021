import { Box, Flex, Text } from "@chakra-ui/react";
import { Blockquote } from "../labels/blockquote/blockquote";
import styles from "./postText.module.scss";
interface PostTextProps {
  allTexts: {
    textBlocks: [
      {
        textBlock: {
          text: string;
          textStyle: string;
        };
      }
    ];
  };
}
export const PostText = ({ allTexts }: PostTextProps) => {
  return (
    <Flex
      padding={"32px 0px 32px 0px"}
      margin={"0 9%"}
      flexDirection={"column"}
      className={`wrapper ${styles.textWrapper}`}
      gap="32px"
    >
      {allTexts.textBlocks?.map((oneBlock, index) => {
        return oneBlock.textBlock.textStyle === "blockquote" ? (
          <Box
            className={styles.blockQuote}
            marginTop={"64px"}
            marginBottom={"64px"}
            marginLeft={"82px"}
          >
            <Blockquote
              key={oneBlock.textBlock.text}
              text={oneBlock.textBlock.text}
              textStyle={oneBlock.textBlock.textStyle}
            />
          </Box>
        ) : (
          <Text
            className={styles.text}
            width="66.67%"
            marginLeft={"auto"}
            marginRight={"auto"}
            key={oneBlock.textBlock.text}
            textStyle={{ base: `${oneBlock.textBlock.textStyle}` }}
            as={index === 0 ? "h3" : "p"}
          >
            {oneBlock.textBlock.text}
          </Text>
        );
      })}
    </Flex>
  );
};
