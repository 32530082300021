import { Flex, Text, useColorMode } from "@chakra-ui/react";
import { colors } from "../../../styles/js/colors";
import { Blockquote } from "../../atoms/labels/blockquote/blockquote";
import styles from "./testimonial.module.scss";

interface TestimonialProps {
  post: {
    quote: string;
    author: string;
    authorJobTitle: string;
  };
}

export const Testimonial = ({ post }: TestimonialProps) => {
  const { colorMode } = useColorMode();
  return (
    <Flex
      className={styles.testimonialWrapper}
      backgroundColor={colorMode === "dark" ? colors.navy : "white"}
      flexDir={"column"}
      padding={"128px 17%"}
      margin={"64px"}
    >
      <Blockquote
        className={styles.blockquoteStyles}
        text={post.quote}
        textStyle={"blockquote"}
        textColor={colorMode === "dark" ? "white" : colors.spruce}
      />
      <Text
        textStyle={{ base: "heading8Medium" }}
        color={colorMode === "dark" ? "white" : colors.navy}
        paddingTop={"32px"}
      >
        {post.author}
      </Text>
      <Text
        textStyle={{ base: "paragraphMDRegular" }}
        color={colorMode === "dark" ? "white" : colors.navy}
      >
        {post.authorJobTitle}
      </Text>
    </Flex>
  );
};
