import React from "react";
import { Box } from "@chakra-ui/react";

const TwitterIcon = () => {
  return (
    <Box
      width={"48px"}
      height={"48px"}
      borderRadius={"24px"}
      border={"2px solid white"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      cursor={"pointer"}
      _hover={{
        background: "white",
        path: {
          fill: "black",
        },
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.5331 6.85786C21.5483 7.07105 21.5483 7.28429 21.5483 7.49747C21.5483 14 16.5991 21.4924 7.55333 21.4924C4.7665 21.4924 2.17768 20.6853 0 19.2843C0.395955 19.33 0.776628 19.3452 1.18782 19.3452C3.48728 19.3452 5.60407 18.5686 7.29443 17.2437C5.13199 17.198 3.31979 15.7818 2.69542 13.8325C3.00001 13.8782 3.30456 13.9086 3.62439 13.9086C4.066 13.9086 4.50766 13.8477 4.9188 13.7412C2.66499 13.2843 0.974582 11.3046 0.974582 8.91371V8.85282C1.62938 9.21831 2.39087 9.44673 3.19792 9.47715C1.87304 8.59389 1.00505 7.08629 1.00505 5.38069C1.00505 4.46699 1.24866 3.62943 1.67508 2.89846C4.09642 5.88323 7.73605 7.83244 11.8172 8.04568C11.7411 7.68019 11.6954 7.29952 11.6954 6.9188C11.6954 4.2081 13.8883 2 16.6142 2C18.0304 2 19.3096 2.59391 20.2081 3.5533C21.3198 3.34011 22.3858 2.92893 23.33 2.36549C22.9644 3.50765 22.1878 4.46704 21.1675 5.07614C22.1574 4.96959 23.1168 4.69542 24 4.31474C23.3301 5.28933 22.4924 6.15731 21.5331 6.85786Z"
          fill="white"
        />
      </svg>
    </Box>
  );
};
export default TwitterIcon;
