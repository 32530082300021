export const linkResolver = (link: string, forceFrontEnd?: boolean) => {
	try {
		typeof window === 'undefined'
			? console.log('Executing on the server')
			: console.log('Executing on the client');

		if (!process.env.NEXT_PUBLIC_WORDPRESS_API_URL) return link;
		const {pathname, host} = new URL(link);

		if (forceFrontEnd) return process.env.NEXT_PUBLIC_APP_URL + pathname;
		// if external
		const {host: host2} = new URL(process.env.NEXT_PUBLIC_WORDPRESS_API_URL);
		if (host !== host2) return link;

		return process.env.NEXT_PUBLIC_APP_URL + pathname;
	} catch (e) {
		console.error(
			'WARNING - LinkResolver - Failed: ',
			link,
			' - Error: ',
			JSON.stringify(e),
		);
		console.trace();

		return link;
	}
};
