/** @format */

import {useState} from 'react';
import styles from './post-header.module.scss';
import Link from 'next/link';
import {Box, Image, Flex, Text, useColorMode} from '@chakra-ui/react';
//svg's
import {colors} from '../../../styles/js/colors';
import {BlogCardDetails} from '../../molecules/blog-card-details/blog-card-details';
//custom components
import MainMenu from '../main-menu/main-menu';
import LineLabel from '../../atoms/labels/label-with-line/line-label';
import TextButtonArrowIcon from '../../atoms/buttons/text-button-arrow-icon/text-button-arrow-icon';
import {ArrowLeft} from '../../../icons/arrow-left';
import ChevronRight from '../../../icons/chevron-right';
import {BulbLogo} from '../../molecules/main-menu-nav/bulb-logo';
import CustomIconButton from '../../atoms/buttons/icon-button/icon-button';

interface PostHeaderProps {
	data: {
		backgroundColor: string | null;
		backToTitle: string;
		heroText: string;
		heroDescription: string;
		postType: string;
		blogType: Array<string>;
		date: string;
		heroImage: {sourceUrl: string; altText?: string};
		breadcrumb: [{title: string}];
		projectsLayout: {category: Array<{name: string}>; backToTitle: string};
		servicesLayout: {
			category: Array<{name: string}>;
			breadcrumb: {title: string; slug: string};
		};
		newsAndInsightsLayout: {
			category: Array<{name: string}>;
			backToTitle: string;
		};
		otherLayout: {
			backToTitle: string;
		};
	};
}
const PostHeader = ({data}: PostHeaderProps) => {
	const {colorMode} = useColorMode();
	const [showMainMenu, setShowMainMenu] = useState(false);
	function _handleOnClick() {
		setShowMainMenu(!showMainMenu);
	}
	return (
		<>
			{showMainMenu && <MainMenu onClick={_handleOnClick} />}
			<>
				<Box
					backgroundColor={data.backgroundColor || colors.navy}
					width={'100%'}
					color={colors.white}>
					<Box className={`wrapper`}>
						<Box
							display={'flex'}
							alignItems={'center'}
							justifyContent={'space-between'}>
							<Link href={'/'}>
								<a>
									{colorMode === 'dark' ? (
										<Box cursor={'pointer'}>
											<BulbLogo fill='white' />
										</Box>
									) : (
										<Box cursor={'pointer'}>
											<BulbLogo fill={'white'} />
										</Box>
									)}
								</a>
							</Link>
							<CustomIconButton
								variant={'transparent'}
								text={'Menu'}
								rightIcon={true}
								leftIcon={false}
								icon={<div className={`${styles.burger}`}></div>}
								onClick={_handleOnClick}
								className={styles.button}
							/>
						</Box>

						<Flex
							className={styles.contentWrapper}
							flexDirection='column'
							paddingTop='104px'>
							<Link href={'/news-insights'}>
								<a>
									<Box paddingBottom='32px'>
										{data.postType === 'News & Insights' ||
										data.postType === 'Projects' ? (
											<Link
												href={
													data.postType === 'News & Insights'
														? '/news-insights'
														: data.postType === 'Projects'
														? '/projects'
														: '/'
												}>
												<TextButtonArrowIcon
													//this variant adds animation to the text
													variant='keepReading'
													textBaseStyle='heading8Medium'
													text={
														data.postType === 'News & Insights'
															? data.newsAndInsightsLayout.backToTitle
															: data.projectsLayout.backToTitle
													}
													color={'white'}
													icon={<ArrowLeft />}
													onClick={undefined}
													leftIcon={true}
													rightIcon={false}
												/>
											</Link>
										) : data.otherLayout.backToTitle ? (
											<LineLabel labelText={data.otherLayout.backToTitle} />
										) : (
											<Flex align={'center'}>
												<Link href={`/services`}>
													<Text
														className={styles.text}
														cursor={'pointer'}
														paddingRight={'16px'}
														textStyle={{base: 'heading8Medium'}}>
														Services
													</Text>
												</Link>
												{data.servicesLayout.breadcrumb ? (
													<Flex key={data.servicesLayout.breadcrumb.title}>
														<ChevronRight
															stroke='white'
															width='6px'
															height='32px'
														/>
														<Link
															href={`/services/${data.servicesLayout.breadcrumb.slug}`}>
															<Text
																className={styles.text}
																cursor={'pointer'}
																// className={styles.text}
																paddingLeft={'16px'}
																paddingRight={'16px'}
																textStyle={{base: 'heading8Medium'}}>
																{data.servicesLayout.breadcrumb.title}
															</Text>
														</Link>
													</Flex>
												) : (
													<></>
												)}
												<div
													style={{
														borderBottom: '2px solid #D4E157',
														width: '64px',
														marginLeft: '16px',
													}}></div>
											</Flex>
										)}
									</Box>
								</a>
							</Link>
							<Text
								color={colors.white}
								className={styles.heroText}
								textStyle={{base: 'heading2Medium'}}
								maxWidth={'90%'}
								paddingBottom={'64px'}
								as={'h1'}>
								{data.heroText}
							</Text>
							<Text
								color={colors.white}
								//  className={styles.heroText}h
								textStyle={{base: 'paragraphLGRegular'}}
								maxWidth={'856px'}
								paddingBottom={'64px'}>
								{data.heroDescription}
							</Text>
							{data.postType === 'News & Insights' && (
								<Flex flexDirection='row'>
									{data.blogType?.length &&
										data.blogType.map(blogType => {
											return (
												blogType !== 'None' && (
													<BlogCardDetails type={blogType} />
												)
											);
										})}
									<Text
										letterSpacing={'-0.16px'}
										width={'fit-content'}
										fontWeight={'400'}
										padding={'4px 16px'}>
										{data.date}
									</Text>
								</Flex>
							)}
						</Flex>
					</Box>
				</Box>
				<Flex
					background={`linear-gradient(180deg, ${
						data.backgroundColor || colors.navy
					} 40%, #FFF 40%)`}>
					<Flex justifyContent={'center'} width={'100%'} className={`wrapper`}>
						{data.heroImage?.sourceUrl && (
							<Image
								width={'100%'}
								objectFit={'fill'}
								src={data.heroImage?.sourceUrl}
								alt={data.heroImage?.altText}></Image>
						)}
					</Flex>
				</Flex>
			</>
		</>
	);
};
export default PostHeader;
