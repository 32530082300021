/** @format */

import {Flex, Grid, Image, Text, Box, AspectRatio} from '@chakra-ui/react';
import styles from './images-grid.module.scss';

import {ImageProps} from '../../../interfaces';

interface ImagesGridProps {
	post: {
		images: Array<ImageProps>;
	};
}

export const ImagesGrid = ({post}: ImagesGridProps) => {
	return (
		<Box>
			<Grid
				className={styles.grid}
				gridTemplateColumns={'1fr 1fr'}
				backgroundColor='#ffffff'
				justifyItems='center'>
				{post.images?.map((oneImage: ImageProps) => {
					return (
						<Flex flexDirection='column' width={'100%'} key={Math.random()}>
							<AspectRatio
								ratio={
									Number(oneImage.aspectRatio.split(':')[0]) /
									Number(oneImage.aspectRatio.split(':')[1])
								}>
								<Image
									src={oneImage.image?.link}
									alt={oneImage.image?.altText || 'project-card-image'}
									objectFit={'fill'}></Image>
							</AspectRatio>
							{oneImage.textUnderImage.textTitle ? (
								<Flex flexDirection='column'>
									<Text
										paddingTop='64px'
										maxWidth='628px'
										fontSize='32px'
										fontWeight='500'
										lineHeight='40px'
										letterSpacing='-0.64px'>
										{oneImage.textUnderImage.textTitle}
									</Text>
								</Flex>
							) : (
								<></>
							)}
							{oneImage.textUnderImage.text ? (
								<Flex flexDirection='column'>
									<Text
										paddingTop='8px'
										maxWidth='628px'
										fontSize='20px'
										fontWeight='400'
										lineHeight='32px'
										letterSpacing='-0.2px'>
										{oneImage.textUnderImage.text}
									</Text>
								</Flex>
							) : (
								<></>
							)}
						</Flex>
					);
				})}
			</Grid>
		</Box>
	);
};
