/** @format */

import {linkResolver} from './lib/linkResolver';

export const footerData = {
	policies: [
		{
			policy: 'Privacy Policy',
		},
		{
			policy: 'Cookies Policy',
		},
	],
	contactDetails: {
		email: 'info@bulbstudios.com',
		phoneNumber: '+44 (0) 116 25 45 075',
	},
	follow: [
		{
			socialLinks: {
				url: 'https://www.instagram.com/bulbstudios/',
				title: 'Instagram',
			},
		},
		{
			socialLinks: {
				url: 'https://www.linkedin.com/company/bulb-studios-ltd/',
				title: 'Linkedin',
			},
		},
		{
			socialLinks: {
				url: 'https://twitter.com/BulbStudios',
				title: 'Twitter',
			},
		},
		{
			socialLinks: {
				url: 'https://vimeo.com/bulbstudios',
				title: 'Vimeo',
			},
		},
	],
	footerMenuLinks: [
		{
			link: {
				title: 'Projects',
				url: '/projects',
			},
		},
		{
			link: {
				title: 'Services',
				url: '/services',
			},
		},
		{
			link: {
				title: 'About',
				url: '/about',
			},
		},
		{
			link: {
				title: 'News & Insights',
				url: '/news-insights',
			},
		},
		{
			link: {
				title: 'Contact',
				url: '/contact',
			},
		},
	],
};

interface LinkProp {
	target: string;
	title: string;
	url: string;
}

export const mapLink = (linkData: LinkProp) => {
	const {target = '', title = '', url = '#', ...props} = linkData;
	return {
		target,
		title,
		url: linkResolver(url),
		...props,
	};
};

export const getLinksMapping = data => {
	if (!data) return null;
	const links = Array.isArray(data)
		? data.map((link: LinkProp) => mapLink(link))
		: mapLink(data);

	return links;
};
