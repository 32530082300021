import {useEffect} from 'react';
import {patternsEnum} from '../enums';
import {useDispatch} from 'react-redux';
import {setFirstFilter} from '../redux/slices/filtersSlice';

import Header from '../components/organisms/header/header';
import Footer from '../components/organisms/footer/footer';
import PostHeader from '../components/organisms/post-header/post-header';
import BlogFilter from '../components/molecules/blog-filter/blog-filter';
import {BlogCardsGrid} from '../components/organisms/blog-card-grid/blog-card-grid';
import {ViewMoreProjects} from '../components/molecules/cta-view-more-projects/cta-view-more-projects';
import {CTABanner} from '../components/organisms/cta-banner/cta-banner';
import {PostText} from '../components/atoms/text/postText';
import {BlogFooter} from '../components/organisms/blog-footer/blog-footer';
import {Images} from '../components/molecules/images/images';
import {KeepReading} from '../components/organisms/keep-reading/keep-reading';
import {Wysiwyg} from '../components/organisms/wysiwyg/wysiwyg';
import {WysiwygVideo} from '../components/organisms/wysiwyg-video/wysiwyg-video';
import {TextAndImage} from '../components/organisms/text-and-image/text-and-image';
import {StatAndImage} from '../components/organisms/stat-and-Image/stat-and-image';
import {StatsGrid} from '../components/organisms/stats-grid/stats-grid';
import {ImagesGrid} from '../components/organisms/images-grid/images-grid';
import {Expertise} from '../components/organisms/expertise/expertise';
import {Testimonial} from '../components/organisms/testimonial/testimonial';
import {CustomCards} from '../components/organisms/custom-cards/custom-cards-grid';
import {Highlights} from '../components/organisms/highlights/highlights';
import {Services} from '../components/organisms/services/services';
import TitleWithImage from '../components/organisms/title-with-image/title-with-image';
import {ServicesGroup} from '../components/organisms/services-group/services-group';
import HomePageHeader from '../components/organisms/home-page-header/home-page-header';
import ScrollableTabs from '../components/molecules/scrollable-tabs/scrollable-tabs';
import HeaderWithCalendar from '../components/organisms/header-with-calendar/header-with-calendar';
import LocationsGrid from '../components/organisms/locations-grid/locations-grid';
import ExploreButton from '../components/molecules/explore-button/explore-button';
import {setMenuData} from '../redux/slices/menuSlice';
import ProjectGrid from '../components/organisms/project-grid/project-grid';
import MainMenuData from '../components/molecules/main-menu-data/main-menu-data';
import ServiceHeader from '../components/organisms/service-header/service-header';
import SectionHeader from '../components/organisms/section-header/section-header';
import RelatedPosts from '../components/organisms/related-posts';
import {wysiwygMapping} from '../components/organisms/wysiwyg/wysiwygMapping';
import {postHeaderMapping} from '../components/organisms/post-header/post-headerMapping';
import {ServicesOverview} from '../components/organisms/services/services-overview';
import {servicesOverviewMapping} from '../components/organisms/services/servicesOverviewMapping';

type PatternProps = {
	fieldGroupName: string;
	postType?: string;
	post?: any;
	colorMode?: string;
};

function Patterns({fieldGroupName, post}: PatternProps) {
	if (!post) return null;
	const flexibleContentName = fieldGroupName.split('_').pop();
	const dispatch = useDispatch();
	try {
		switch (flexibleContentName) {
			case patternsEnum.Header: {
				return <Header data={post} />;
			}
			case patternsEnum.SectionHeader: {
				return <SectionHeader data={post} />;
			}
			case patternsEnum.MainMenu: {
				useEffect(() => {
					dispatch(setMenuData(post));
				}, [post]);
				return <MainMenuData data={post} />;
			}
			case patternsEnum.ProjectGrid: {
				return <ProjectGrid data={post} />;
			}
			case patternsEnum.HomePageHeader: {
				return <HomePageHeader data={post} />;
			}
			case patternsEnum.ScrollableTabs: {
				return <ScrollableTabs data={post} />;
			}
			case patternsEnum.HeaderWithCalendar: {
				return <HeaderWithCalendar data={post} />;
			}
			case patternsEnum.ExploreButton: {
				return <ExploreButton data={post} />;
			}
			case patternsEnum.PostHeader: {
				const data = postHeaderMapping(post);
				return <PostHeader data={data} />;
			}
			case patternsEnum.LocationGrid: {
				return <LocationsGrid data={post} />;
			}
			case patternsEnum.PostText: {
				return <PostText allTexts={post} />;
			}
			case patternsEnum.WYSIWYG: {
				const data = wysiwygMapping(post.wysiwyg);
				return <Wysiwyg post={data} />;
			}
			case patternsEnum.WYSIWYGVideo: {
				return <WysiwygVideo post={post} />;
			}
			case patternsEnum.TitleWithImage: {
				return <TitleWithImage post={post} />;
			}
			case patternsEnum.Expertise: {
				return <Expertise post={post} />;
			}
			case patternsEnum.Services: {
				return <Services post={post} />;
			}
			case patternsEnum.ServicesGroup: {
				return <ServicesGroup post={post} />;
			}
			case patternsEnum.ServicesOverview: {
				const data = servicesOverviewMapping(post);
				return <ServicesOverview {...data} />;
			}
			case patternsEnum.CustomCards: {
				return <CustomCards post={post} />;
			}
			case patternsEnum.TextAndImage: {
				return <TextAndImage post={post} />;
			}
			case patternsEnum.StatAndImage: {
				return <StatAndImage post={post} />;
			}
			case patternsEnum.ImagesGrid: {
				return <ImagesGrid post={post} />;
			}
			case patternsEnum.StatsGrid: {
				return <StatsGrid post={post} />;
			}
			case patternsEnum.ServiceHeader: {
				return <ServiceHeader post={post} />;
			}

			case patternsEnum.FilterItems: {
				//sets the filters
				useEffect(() => {
					dispatch(setFirstFilter(post.filterItems[0].filterName));
				}, [post]);
				return <BlogFilter filters={post} />;
			}
			case patternsEnum.BlogAuthor: {
				return <BlogFooter post={post} />;
			}
			case patternsEnum.Image: {
				return <Images post={post} />;
			}
			case patternsEnum.BlogCardsGrid: {
				return <BlogCardsGrid />;
			}
			case patternsEnum.Highlights: {
				return <Highlights post={post} />;
			}
			case patternsEnum.KeepReading: {
				return <KeepReading post={post} />;
			}
			case patternsEnum.RelatedPosts: {
				return <RelatedPosts post={post} />;
			}
			case patternsEnum.Testimonial: {
				return <Testimonial post={post} />;
			}
			case patternsEnum.CTAButton: {
				return <ViewMoreProjects post={post} />;
			}
			case patternsEnum.CTABanner: {
				return <CTABanner post={post} />;
			}
			case patternsEnum.Footer: {
				return <Footer footerData={post} />;
			}
			default:
				break;
		}
	} catch (e) {
		console.error(`Issue with pattern: ${flexibleContentName}`);
		console.error(e);
		throw e;
	}
	return null;
}

export default Patterns;
