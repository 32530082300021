import {gql} from '@apollo/client';
import {getApolloClient} from './apollo-client';
import {getPartialBackgroundFields} from './acf';

export const getBlogs = async (id, endCursor?) => {
	//fetch first 10 posts
	const CPTtoQuerry =
		id === '/news-insights'
			? 'allNewsInsights'
			: id === '/projects'
			? 'projects'
			: 'services';
	const graphqlSingleName =
		id === '/news-insights'
			? 'NewsInsights'
			: id === '/projects'
			? 'Project'
			: 'Service';
	const client = getApolloClient();
	try {
		if (endCursor) {
			const {data} = await client.query({
				query: gql`
          query getBlogs {
            ${CPTtoQuerry} (first: 9, after: "${endCursor}") {
              edges {
                node {
                  title
                  slug
                  date
                  pageLayout {
                    flexibleContent {
                      ... on ${graphqlSingleName}_Pagelayout_FlexibleContent_PostHeader {
                        postType
                        cardView {
                          excerpt
                          title
                          thumbnail {
                            sourceUrl
                            altText
                          }
                        }
                        projectsLayout {
                          category {
                            ...on ProjectType{
                              name
                            }
                          }
                        }
                        servicesLayout {
                          category {
                            ...on ServiceType{
                              name
                            }
                          }
                        }
                        ${getPartialBackgroundFields()}
                        newsAndInsightsLayout {
                          category {
                            ...on NewsInsightsType{
                              name
                            }
                          }
                        }
                        otherLayout {
                          breadcrumb
                        }
                        heroText
                        heroDescription
                        date
                        heroImage {
                          link
                          altText
                        }
                      }
                    }
                  }
                }
              }
              pageInfo {
                endCursor
                hasNextPage
              }
            }
          }
        `,
			});

			return data;
		} else {
			const {data} = await client.query({
				query: gql`
          query getBlogs {
            ${CPTtoQuerry} (first: 50) {
              edges {
                node {
                  title
                  slug
                  date
                  pageLayout {
                    flexibleContent {
                      ... on ${graphqlSingleName}_Pagelayout_FlexibleContent_PostHeader {
                        postType
                        cardView {
                          excerpt
                          title
                          thumbnail {
                            sourceUrl
                            altText
                          }
                        }
                        projectsLayout {
                          category {
                            ...on ProjectType{
                              name
                            }
                          }
                        }
                        servicesLayout {
                          category {
                            ...on ServiceType{
                              name
                            }
                          }
                        }
                        ${getPartialBackgroundFields()}
                        newsAndInsightsLayout {
                          category {
                            ...on NewsInsightsType{
                              name
                            }
                          }
                        }
                        otherLayout {
                          breadcrumb
                        }
                        heroText
                        heroDescription
                        date
                        heroImage {
                          link
                          altText
                        }
                      }
                    }
                  }
                }
              }
              pageInfo {
                endCursor
                hasNextPage
              }
            }
          }
        `,
			});
			return data;
		}
	} catch (error) {
		console.error(`[Blogs]: ${error.message}`);
		console.error('error', error);
	}
};
