/** @format */

import {Flex, Text} from '@chakra-ui/react';
import Image from 'next/image';
import styles from './highlight.module.scss';
interface HighLightProps {
	highlightData: {
		svg: {link: string; altText: string};
		blockTitle: string;
		blockDescription: string;
	};
	color: string;
}
export const Highlight = ({highlightData, color}: HighLightProps) => {
	return (
		<Flex
			flexDir={'column'}
			maxW={'400px'}
			flexShrink={0}
			className={styles.wrapper}
			key={highlightData.blockTitle}>
			<Image
				layout={'fixed'}
				width={'64px'}
				height={'64px'}
				src={highlightData.svg?.link}
				alt={highlightData.svg?.altText}
			/>
			<Text
				marginTop='32px'
				textStyle={{base: 'heading6Medium'}}
				paddingBottom={'16px'}
				color={color}
				as='h4'>
				{highlightData.blockTitle}
			</Text>
			<Text textStyle={{base: 'paragraphMDRegular'}} color={color}>
				{highlightData.blockDescription}
			</Text>
		</Flex>
	);
};
