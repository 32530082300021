/** @format */

import {Flex, Text, Image} from '@chakra-ui/react';

const TitleWithImage = ({post}) => {
	return (
		<Flex
			padding='128px 64px 0px 64px'
			backgroundColor='#F2F2F2'
			flexDirection='column'>
			<Text
				fontSize='40px'
				fontWeight='500'
				lineHeight='48px'
				letterSpacing='-0.8px'
				maxWidth='628px'
				marginBottom='56px'>
				{post.title}
			</Text>
			<Image
				src={post.image?.link}
				alt={post.image?.altText || 'title-with-image'}
			/>
			<Text
				marginTop='32px'
				fontWeight='500'
				fontSize='32px'
				lineHeight='40px'
				letterSpacing='-0.64px'
				maxWidth='628px'>
				{post.textUnderImage.textTitle}
			</Text>
			<Text
				marginTop='8px'
				fontWeight='400'
				fontSize='20px'
				lineHeight='32px'
				letterSpacing='-0.2px'
				maxWidth='628px'>
				{post.textUnderImage.text}
			</Text>
		</Flex>
	);
};

export default TitleWithImage;
