import { MouseEventHandler, useEffect } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import styles from "./menu.module.scss";
import Link from "next/link";
interface MenuProps {
  data: Array<{ link: { title: string; url: string } }>;
  onClick?: MouseEventHandler<HTMLParagraphElement>;
}
const Menu = ({ data, onClick }: MenuProps) => {
  useEffect(() => {
    const links = document.getElementsByClassName(styles.menuLinkForNav);
    if (links != null) {
      for (let i = 0; i < links.length; i++) {
        setTimeout(() => {
          links[i]?.classList.add(styles.menuLinkForNavOpen);
        }, 300 * i);
      }
    }
  }, []);
  return (
    <Box key={data?.toString()}>
      {data?.map((menuItem, index) => {
        //replaces default wordpress endoding of &
        let title: string = menuItem?.link?.title;
        if (title.includes("&amp;")) {
          title = title.replace("&amp;", "&");
        }
        return (
          <Flex className={styles.menuLinkForNav} key={menuItem.link.title}>
            <Link href={menuItem?.link.url}>
              <a>
                <Text
                  className={styles.text}
                  key={menuItem?.link.title}
                  height={"40px"}
                  width={"fit-content"}
                  boxSizing="border-box"
                  cursor={"pointer"}
                  fontSize="40px"
                  lineHeight="48px"
                  fontWeight="500"
                  color="white"
                  borderBottom="2px solid #121D33"
                  //removes the margin of the last item
                  // marginBottom={`${index === data.length - 1 ? "0px" : "24px"}`}
                  marginBottom="32px"
                  letterSpacing={"-0.8px"}
                  //on text click hide the menu
                  onClick={onClick}
                >
                  {title}
                </Text>
              </a>
            </Link>
          </Flex>
        );
      })}
    </Box>
  );
};
export default Menu;
