import {BlogCardsProps} from '../classes/blog-cards';
import {BlogCardProps} from '../classes/blog-card';

function formatDate(dateString: string): string {
	// Parse the input date string
	const date = new Date(dateString);

	// Check if the date is valid
	if (isNaN(date.getTime())) {
		throw new Error('Invalid date string');
	}

	// Define the options for formatting
	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	};

	// Format the date using the browser's locale
	return new Intl.DateTimeFormat(navigator.language, options).format(date);
}

export interface DataToBeFormatedNewsAndInsights {
	allNewsInsights: {
		edges: [
			{
				node: {
					slug: string;
					title: string;
					date: string;
					pageLayout: {
						flexibleContent: [
							{
								projectsLayout: {category: Array<{name: string}>};
								servicesLayout: {category: Array<{name: string}>};
								newsAndInsightsLayout: {category: Array<{name: string}>};
								blogType: string;
								date: string;
								heroText: string;
								postType: string;
								heroDescription: string;
								heroImage: {
									link: string;
								};
								cardView: {
									title: string;
									excerpt: string;
									thumbnail: {
										sourceUrl: string;
									};
								};
							},
						];
					};
				};
			},
		];
	};
}

export const NewsAndInsightsCardsDataFormater = (
	dataToBeFormated: DataToBeFormatedNewsAndInsights,
) => {
	if (dataToBeFormated?.allNewsInsights?.edges) {
		const allBlogCards = new BlogCardsProps();
		dataToBeFormated.allNewsInsights.edges.forEach(NewsPost => {
			const blogCard = new BlogCardProps();
			// blogCard.date = formatDate(NewsPost.node.date);
			blogCard.date = NewsPost.node.pageLayout.flexibleContent[0].date; // CMS field is not returning date, but should not be a field. Cannot remove because of breaking changes and the complexity of this simple project has gone into.
			blogCard.heroText = NewsPost.node.pageLayout.flexibleContent[0].heroText;
			blogCard.slug = NewsPost.node.slug;
			blogCard.postType = NewsPost.node.pageLayout.flexibleContent[0].postType;
			blogCard.heroDescription =
				NewsPost.node.pageLayout.flexibleContent[0].heroDescription;
			blogCard.heroImage.link =
				NewsPost.node.pageLayout.flexibleContent[0]?.heroImage?.link;
			blogCard.cardView = NewsPost.node.pageLayout.flexibleContent[0]?.cardView;
			blogCard.newsAndInsightsLayout =
				NewsPost.node.pageLayout.flexibleContent[0]?.newsAndInsightsLayout;
			blogCard.projectsLayout =
				NewsPost.node.pageLayout.flexibleContent[0]?.projectsLayout;
			allBlogCards.blogCards.push(blogCard);
		});

		return allBlogCards;
	}
};
