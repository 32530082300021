import {keyframes} from '@emotion/react';
import styled from '@emotion/styled';

const animateSvgStroke = keyframes`
  0% {
    stroke-dashoffset: 74.09871673583984px;
    stroke-dasharray: 74.09871673583984px;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 74.09871673583984px;
  }
`;

const SvgPath = styled.path`
	animation: ${animateSvgStroke} 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s
		both;
`;

export const SmileyIcon = props => {
	return (
		<svg
			viewBox='0 0 24 24'
			focusable='false'
			className='chakra-icon css-1dna56'
			width='24'
			height='24'
			{...props}>
			<svg
				width='22'
				height='22'
				viewBox='0 0 22 22'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'>
				<SvgPath
					d='M7 13C7 13 8.5 15 11 15C13.5 15 15 13 15 13M8 8H8.01M14 8H14.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z'
					stroke='black'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
					className='svg-elem-1'
				/>
			</svg>
		</svg>
	);
};
