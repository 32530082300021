import React from "react";
import { Text } from "@chakra-ui/react";
import { colors } from "../../../../styles/js/colors";
import styles from "./blockquote.module.scss";
interface BlockquoteProps {
  text: string;
  textStyle: string;
  textColor?: string;
  className?: string;
}

export const Blockquote = (props: BlockquoteProps) => {
  return (
    <Text
      className={`${props.className} ${styles.blockquote}`}
      key={props.text}
      paddingLeft={"32px"}
      borderLeft={`4px solid ${colors.lime}`}
      textStyle={{ base: props.textStyle }}
      color={props.textColor}
    >
      {props.text}
    </Text>
  );
};
