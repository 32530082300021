import React from "react";
import { Box } from "@chakra-ui/react";

const VimeoIcon = () => {
  return (
    <Box
      width={"48px"}
      height={"48px"}
      borderRadius={"24px"}
      border={"2px solid white"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      cursor={"pointer"}
      _hover={{
        background: "white",
        path: {
          fill: "black",
        },
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.9888 6.79354C23.8817 9.12921 22.2531 12.3274 19.0925 16.388C15.8301 20.6308 13.0712 22.7522 10.8105 22.7522C9.41232 22.7522 8.22841 21.4611 7.25879 18.879C5.37311 11.9577 4.56955 7.90244 3.01066 7.90244C2.82852 7.90244 2.20174 8.28279 1.12498 9.03278L0 7.57566C2.76423 5.14893 5.40525 2.44898 7.06058 2.29898C8.93018 2.11684 10.0766 3.39717 10.5105 6.12926C12.048 15.8523 12.7283 17.3201 15.5247 12.9166C16.5265 11.3309 17.0675 10.1256 17.1425 9.29527C17.3997 6.83639 15.2247 7.00246 13.7515 7.63459C14.9301 3.77217 17.1854 1.8972 20.5121 2.00434C22.9656 2.06863 24.1281 3.67038 23.9888 6.79354Z"
          fill="white"
        />
      </svg>
    </Box>
  );
};
export default VimeoIcon;
