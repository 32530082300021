import { Box, Flex } from "@chakra-ui/react";
import LineLabel from "../../atoms/labels/label-with-line/line-label";
import InstagramIcon from "../../../icons/instagram-icon";
import LinkedinIcon from "../../../icons/linkedin-icon";
import TwitterIcon from "../../../icons/twitter-icon";
import VimeoIcon from "../../../icons/vimeo-icon";
import { colors } from "../../../styles/js/colors";
interface FollowProps {
  followData: Array<{ socialLinks: { title: string; url: string } }>;
}
const Follow = ({ followData }: FollowProps) => {
  return (
    <Flex
      height={"fit-content"}
      backgroundColor={colors.navy}
      alignItems={"start"}
      justifyContent={"space-between"}
      flexDirection={"column"}
      width={"300px"}
    >
      <Box paddingBottom={"24px"}>
        <LineLabel
          key={"Follow"}
          fontSize="20px"
          fontWeight="500"
          letterSpacing={"-0.4px"}
          lineHeight="32px"
          labelText={"Follow"}
          color={colors.white}
        ></LineLabel>
      </Box>
      <Flex justifyContent={"space-around"} width="100%" gap={"16px"}>
        {followData.length > 0 &&
          followData?.map((socialLink) => {
            return (
              <div key={socialLink.socialLinks.title}>
                {iconResolver(
                  socialLink.socialLinks.title,
                  socialLink.socialLinks.url
                )}
              </div>
            );
          })}
      </Flex>
    </Flex>
  );
};
export default Follow;

const iconResolver = (title: string, link: string) => {
  switch (title) {
    case "Instagram":
      return (
        <a href={link} rel="noreferrer" target="_blank" key={title} aria-label="Instagram">
          <InstagramIcon />
        </a>
      );
    case "Linkedin":
      return (
        <a href={link} rel="noreferrer" target="_blank" key={title} aria-label="Linkedin">
          <LinkedinIcon />
        </a>
      );
    case "Twitter":
      return (
        <a href={link} rel="noreferrer" target="_blank" key={title} aria-label="Twitter">
          <TwitterIcon />
        </a>
      );
    case "Vimeo":
      return (
        <a href={link} rel="noreferrer" target="_blank" key={title} aria-label="Vimeo">
          <VimeoIcon />
        </a>
      );
    default:
      return (
        <a href={link} rel="noreferrer" target="_blank" key={title}>
          <VimeoIcon />
        </a>
      );
  }
};
