/** @format */

import styles from './blog-footer.module.scss';
import {colors} from '../../../styles/js/colors';
import {Box} from '@chakra-ui/react';
//custom components
import {BlogAuthor} from '../../molecules/blog-author/blog-author';

interface BlogFooterProps {
	post: {
		authorName: string;
		authorJobTitle: string;
		authorImage: {
			link: string;
			altText: string;
		};
	};
}
export const BlogFooter = ({post}: BlogFooterProps) => {
	return (
		<Box
			className={styles.authorWrapper}
			margin={'0 20%'}
			borderTop={`2px solid ${colors.gray[10]}`}
			paddingTop={'30px'}
			paddingBottom={'64px'}>
			<BlogAuthor
				link={post.authorImage?.link}
				altText={post.authorImage?.altText}
				authorName={post.authorName}
				authorJobTitle={post.authorJobTitle}></BlogAuthor>
		</Box>
	);
};
