import { useEffect, useState } from "react";
import styles from "./blog-card-grid.module.scss";
import { Box } from "@chakra-ui/react";
//redux
import { useSelector } from "react-redux";
import { selectBlogCards } from "../../../redux/slices/blogCardsSlice";

//custom components
import { BlogCard, SkeletonBlogCard } from "../../molecules/blog-card/blog-card";
import { selectedFiltersState } from "../../../redux/slices/filtersSlice";
import { useRouter } from "next/router";

export const BlogCardsGrid = () => {
  const router = useRouter();
  //get data from redux
  const reduxBlogCards = useSelector(selectBlogCards);
  const selectedFilters = useSelector(selectedFiltersState);
  const [cardsForDisplay, setCarsForDisplay] = useState(
    [] as undefined as typeof reduxBlogCards
  );

  const [cardsLoading, setCardsLoading] = useState(true);
  const placeholderCardsCount = 10;

  useEffect(() => {
    let tempCardsForDisplay = [] as undefined as typeof reduxBlogCards;
    if (
      selectedFilters.selectedFilters[0] !== "All projects" &&
      selectedFilters.selectedFilters[0] !== "All articles"
    ) {
      if (router.asPath === "/projects" || router.asPath === "/projects/") {
        reduxBlogCards.forEach((card) => {
          if (card.projectsLayout.category) {
            card.projectsLayout.category.forEach((category) => {
              if (category?.name === selectedFilters.selectedFilters[0]) {
                tempCardsForDisplay.push(card);
                return;
              }
            });
          }
        });
      } else if (router.asPath === "/news-insights" || router.asPath === "/news-insights/") {
        reduxBlogCards.forEach((card) => {
          if (card.newsAndInsightsLayout.category) {
            card.newsAndInsightsLayout.category.forEach((category) => {
              if (category?.name === selectedFilters.selectedFilters[0]) {
                tempCardsForDisplay.push(card);
                return;
              }
            });
          }
        });
      }
    } else {
      reduxBlogCards.forEach((card) => tempCardsForDisplay.push(card));
    }

    setCarsForDisplay(tempCardsForDisplay);
    setCardsLoading(false);
  }, [selectedFilters, reduxBlogCards]);
  return (
    <Box>
      <Box
        className={`${styles.cardsGrid} wrapper`}
        display={"grid"}
        gridTemplateColumns="1fr 1fr"
        gap={"56px"}
        padding="44px 64px"
        alignSelf={"center"}
        justifySelf={"center"}
      >
        {cardsForDisplay
          ? cardsForDisplay?.map((card) => {
              if(card.heroText) {
                return <BlogCard key={`${card.heroText}-${Math.random()}`} cardData={card}/>;
              }
            })
          : null}
        {cardsLoading &&
          placeholderCardsCount &&
            [...Array(placeholderCardsCount)].map((_, i) => (
              <SkeletonBlogCard key={i} />
            ))
        }
      </Box>
    </Box>
  );
};
