/** @format */

import {Box, Flex, Heading, Text} from '@chakra-ui/react';
import Link from 'next/link';
import { useState } from 'react';
import {colors} from '../../../styles/js/colors';
import {fonts} from '../../../styles/js/fonts';
import CustomIconButton from '../../atoms/buttons/icon-button/icon-button';
import {BulbLogo} from '../../molecules/main-menu-nav/bulb-logo';
import MainMenu from '../main-menu/main-menu';
import styles from './home-page-header.module.scss';

const HomePageHeader = ({data}) => {
	const [showMainMenu, setShowMainMenu] = useState(false);
	function _handleOnClick() {
		setShowMainMenu(!showMainMenu);
	}
	return (
		<>
			{showMainMenu && <MainMenu onClick={_handleOnClick} />}
			<Box
				className={styles.headerWrapper}
				width={'100%'}
				backgroundImage={data.video.link ?? data.backgroundImage?.link}
				minHeight={'765px'}
				height={'100vh'}
				// backgroundColor={colors.pine}
				color={colors.white}>
				<video
					id='bgvideo'
					// poster='https://bulb-studios-new.mysites.io/wp-content/uploads/2023/05/Screenshot-2023-05-16-at-11.24.50.png'
					muted
					loop
					autoPlay={true}
					playsInline
					className={styles.video}
				>
					<source src={data.video.link} type='video/mp4' />
				</video>
				<Box
					className={`${styles.headerComponent} wrapper`}
					display={'flex'}
					alignItems={'center'}
					padding={'44px 64px'}
					justifyContent={'space-between'}
					zIndex='10'
					position='relative'>
					<Link href={'/'}>
							<Box cursor={'pointer'}>
								<BulbLogo fill='white' width={'30.14px'} height={'40px'} />
							</Box>
					</Link>
					<CustomIconButton
						variant='transparent'
						text={'Menu'}
						rightIcon={true}
						leftIcon={false}
						icon={<div className={styles.burger}></div>}
						onClick={_handleOnClick}
						className={styles.button}
					/>
				</Box>
				<Flex
					className={`${styles.contentWrapper} wrapper`}
					flexDirection='column'
					justifyContent='center'
					height='90vh'
					marginLeft={'120px'}
					zIndex='10'
					position='relative'>
					<Flex
						alignItems='center'
						justifyContent='center'
						fontWeight='500'
						fontSize='24px'
						letterSpacing='-0.48px'
						lineHeight='36px'
						width='fit-content'>
						<Text color='#59D8C9'>{data.title}</Text>
					</Flex>
					<Heading
						as={'h2'}
						className={styles.headerText}
						fontFamily={fonts.DMSansBold}
						fontSize='120px'
						lineHeight='128px'
						letterSpacing='-3.2px'
						fontWeight='normal'>
						{data.heroText.split(' ')[0]}
						<br /> {data.heroText.split(' ')[1]} {data.heroText.split(' ')[2]}
					</Heading>

					<Text
						color={colors.white}
						className={styles.heroDesc}
						textStyle={{base: 'paragraphLGRegular'}}
						maxWidth={'856px'}
						paddingTop='36px'
						paddingBottom={'64px'}>
						{data.heroDescription}
					</Text>
				</Flex>
			</Box>
			<Flex
				justifyContent={'center'}
				width={'100%'}
				className={styles.headerImage}></Flex>
		</>
	);
};

export default HomePageHeader;
