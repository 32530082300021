/** @format */

import styles from './custom-cards.module.scss';
import {Flex, Grid, Text} from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';
import ChevronRight from '../../../icons/chevron-right';
import TextButtonArrowIcon from '../../atoms/buttons/text-button-arrow-icon/text-button-arrow-icon';
interface CustomCardsProps {
	post: {
		cardSectionHeader: string;
		cardsGrid: [
			{
				cardTitle: string;
				backgroundColor: string;
				cardDescription: string;
				customCard: {
					cardHeading: string;
					cardDescription: string;
					cardImage: {
						link: string;
						altText: string;
					};
					cardLink: string;
				};
			},
		];
	};
}
export const CustomCards = ({post}: CustomCardsProps) => {
	return (
		<Flex backgroundColor='#121D33'>
			<Flex direction='column' className={styles.customCardsWrapper}>
				<Text
					className={styles.header}
					fontSize='40px'
					color='white'
					fontWeight='500'
					lineHeight='48px'
					letterSpacing='-0.8px'
					as='h3'>
					{post.cardSectionHeader}
				</Text>
				<Grid
					className={styles.grid}
					gridTemplateColumns={'1fr 1fr 1fr'}
					padding={'64px 0px'}
					rowGap={'56px'}
					overflowX='auto'
					overflowY='hidden'
					columnGap={'56px'}
					maxW={'1312px'}>
					{post.cardsGrid?.map(oneCard => {
						const card = oneCard?.customCard;
						return (
							<Grid
								// direction="column"
								key={Math.random()}
								className={styles.cardGridGlobal}>
								<Flex
									direction='column'
									border='1px solid white'
									w='394px'
									padding='25px'
									borderRadius='16px'>
									<Image
										className={styles.imageStyle}
										key={Math.random()}
										src={card?.cardImage?.link}
										alt={card?.cardImage?.altText || 'card-image'}
										width='368'
										height='232'
									/>
									<Text
										fontSize='32px'
										lineHeight='40px'
										fontWeight='500'
										letterSpacing='-0.64px'
										marginTop='24px'
										marginBottom='16px'
										color='white'
										as='h3'>
										{card?.cardHeading}
									</Text>
									<Text
										color='white'
										fontSize='20px'
										lineHeight='32px'
										letterSpacing='-0.2px'>
										{card?.cardDescription}
									</Text>
									{card?.cardLink ? (
										<Flex marginTop={'24px'}>
											<Link href={card.cardLink}>
												<a>
													<TextButtonArrowIcon
														variant='keepReading'
														textBaseStyle='heading8Medium'
														text='Learn more'
														color='white'
														icon={<ChevronRight />}
														onClick={undefined}
														leftIcon={false}
														rightIcon={true}
														keepReading={false}
													/>
												</a>
											</Link>
										</Flex>
									) : (
										<></>
									)}
								</Flex>
							</Grid>
						);
					})}
				</Grid>
			</Flex>
		</Flex>
	);
};
