import styles from "./blog-filter.module.scss";
import { Flex } from "@chakra-ui/react";
//redux
import { useSelector } from "react-redux";
import { selectedFiltersState } from "../../../redux/slices/filtersSlice";
//custom components
import FilterButton from "../../atoms/buttons/filter-button/filter-button";
import { Dropdown } from "../../atoms/dropdown/dropdown";
import { FilterProps } from "../../../classes/filter";
interface BlogFilterProps {
  filters: {
    filterItems: [{ filterName: string }];
  };
}
const BlogFilter = ({ filters }: BlogFilterProps) => {
  const selectedFilters: FilterProps = useSelector(selectedFiltersState);

  return (
    <>
      <Flex className={`${styles.buttonWrapper} wrapper`}>
        <Flex
          height="64px"
          borderBottom="2px solid #F2F3F5"
          marginBottom="56px"
          gap="24px"
          width={"100%"}
        >
          {/* Display as many filterButtons as there are in Wordpress */}
          {filters.filterItems?.map((filterItem) => {
            return (
              <FilterButton
                fontSize="16px"
                key={filterItem.filterName}
                filterName={filterItem.filterName}
                isSelected={selectedFilters.selectedFilters.includes(
                  filterItem.filterName
                )}
              />
            );
          })}
        </Flex>
      </Flex>
      <Flex
        className={styles.dropdownWrapper}
        display={"none"}
        padding={"24px 64px"}
        width="100%"
        justifyContent={"center"}
      >
        <Dropdown
          filters={filters.filterItems}
          selectedFilters={selectedFilters.selectedFilters}
        />
      </Flex>
    </>
  );
};
export default BlogFilter;
