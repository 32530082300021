import * as React from "react";

function HalfCirlce(props) {
  return (
    <svg
      version="1.1"
      width="32px"
      height="100px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="rotate(270, 50, 50)">
        <clipPath id="cut-off">
          <rect x="0" y="0" width="64" height="32" />
        </clipPath>
        <circle
          cx="32"
          cy="32"
          r="32"
          fill="#e19b22"
          clipPath="url(#cut-off)"
          {...props}
        />
      </g>
    </svg>
  );

}

export default HalfCirlce;
