import {Box, Flex, Text} from '@chakra-ui/react';
import styles from '../keep-reading/keep-reading.module.scss';
import {colors} from '../../../styles/js/colors';
import {ChevronRightIcon} from '@chakra-ui/icons';
import TextButtonArrowIcon from '../../atoms/buttons/text-button-arrow-icon/text-button-arrow-icon';
import {BlogCard} from '../../molecules/blog-card/blog-card';
import {BlogCardProps} from '../../../classes/blog-card';
import Link from 'next/link';

type layout = 'newsInsightsLayout' | 'projectsLayout' | 'servicesLayout';

type RelatedPostsProps = {
	post: {
		posts: RelatedPostCard[];
		heading: string;
		link: {
			url: string;
			title: string;
		};
	};
};

export type RelatedPostCard = {
	__typename: string;
	uri: string;
	title: string;
	featuredImage: {
		node: {
			sourceUrl: string;
			altText: string;
		};
	};
	date: string;
	pageLayout: {
		flexibleContent: Array<{
			[K in layout]: {
				category: {
					name: string;
				}[];
			};
		}>;
	};
};

const RelatedPosts = ({post}: RelatedPostsProps) => {
	return (
		<Box
			// backgroundColor={colorMode === "light" ? colors.gray[10] : colors.navy}
			backgroundColor={colors.gray[10]}>
			<Flex
				className={`${styles.contentWrapper} wrapper`}
				padding={'128px 64px 96px 64px'}
				flexDir='column'>
				<Flex
					// className={styles.headerWrapper}
					justifyContent={'space-between'}
					alignItems='center'
					width='100%'>
					<Text textStyle={{base: 'heading5Medium'}} as='h3'>
						{post.heading}
					</Text>
					<Link href={post.link.url}>
						<TextButtonArrowIcon
							//this variant adds animation to the text
							variant='keepReading'
							textBaseStyle='heading8Medium'
							text={post.link.title}
							color={colors.navy}
							icon={<ChevronRightIcon />}
							onClick={undefined}
							leftIcon={false}
							rightIcon={true}
							keepReading={true}
						/>
					</Link>
				</Flex>

				<Box
					// className={styles.cardsGrid}
					display={'grid'}
					gridTemplateColumns='1fr 1fr'
					gap={'56px'}
					padding='44px 0px'
					alignSelf={'center'}
					justifySelf={'center'}>
					{post.posts &&
						post.posts?.map(card => {
							return (
								<BlogCard
									key={card.title}
									cardData={card as unknown as BlogCardProps}></BlogCard>
							);
						})}
				</Box>
			</Flex>
		</Box>
	);
};

export default RelatedPosts;
