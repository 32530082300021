import {gql} from '@apollo/client';
import {getApolloClient} from './apollo-client';
import {getPartialBackgroundFields} from './acf';

export const getNewsInsights = async () => {
	const client = getApolloClient();

	try {
		return client.query({
			query: gql`
				query getAllNews {
					allNewsInsights(first: 100) {
						edges {
							node {
								title
								slug
								pageLayout {
									flexibleContent {
										... on NewsInsights_Pagelayout_FlexibleContent_PostHeader {
											date
											heroImage {
												link
												altText
											}
											heroText
											cardView {
												excerpt
												title
												thumbnail {
													sourceUrl
													altText
												}
											}
											postType
											${getPartialBackgroundFields()}
											newsAndInsightsLayout {
												category {
													name
												}
											}
										}
									}
								}
							}
						}
						pageInfo {
							endCursor
							hasNextPage
						}
					}
				}
			`,
		});
	} catch (error) {
		console.error(`[allNewsAndInsights]: ${error.message}`);
		console.error('error', error);
	}
};
export const getProjects = async () => {
	const client = getApolloClient();

	try {
		return client.query({
			query: gql`
				query Projects {
					projects(first: 100) {
						edges {
							node {
								title
								slug
								pageLayout {
									flexibleContent {
										... on Project_Pagelayout_FlexibleContent_PostHeader {
											date
											postType
											heroDescription
											heroImage {
												link
												altText
											}
											heroText
											cardView {
												excerpt
												title
												thumbnail {
													sourceUrl
													altText
												}
											}
										}
									}
								}
							}
						}
						pageInfo {
							endCursor
							hasNextPage
						}
					}
				}
			`,
		});
	} catch (error) {
		console.error(`[Projects]: ${error.message}`);
		console.error('error', error);
	}
};

export const getServices = async () => {
	const client = getApolloClient();

	try {
		return client.query({
			query: gql`
				query Services {
					services(first: 100) {
						edges {
							node {
								title
								slug
								pageLayout {
									flexibleContent {
										... on Service_Pagelayout_FlexibleContent_PostHeader {
											servicesLayout {
												category {
													name
												}
											}
											date
											postType
											heroDescription
											heroImage {
												link
												altText
											}
											heroText
											cardView {
												excerpt
												title
												thumbnail {
													sourceUrl
													altText
												}
											}
										}
									}
								}
							}
						}
						pageInfo {
							endCursor
							hasNextPage
						}
					}
				}
			`,
		});
	} catch (error) {
		console.error(`[Services]: ${error.message}`);
		console.error('error', error);
	}
};

export const getPostCardsByCategory = async (
	postType: string,
	taxonomy: string,
	taxonomyID: string,
) => {
	const client = getApolloClient();
	const id = taxonomyID;

	try {
		const {data} = await client.query({
			query: gql`
        query PostsByTaxonomy($id: ID!) {
          ${taxonomy}(id: $id, idType: ID) {
            id
            ${postType} {
              nodes {
                card {
                  cardviewExcerpt
                  cardviewHeading
                  cardviewLinkText
                  fieldGroupName
                  cardviewFeaturedImage {
                    altText
                    mediaDetails {
                      height
                      width
                    }
                    sourceUrl
                  }
                }
                uri
              }
            }
          }
        }
      `,
			variables: {
				id,
			},
		});

		return data[taxonomy][postType].nodes;
	} catch (e) {
		console.error(
			`[posts][getPostCardsByCategory] Failed to query taxonomy or postType data: ${e.message}`,
		);
		throw e;
	}
};

export const getPostTypeCards = async (postTypePlural: string) => {
	const client = getApolloClient();

	try {
		const {data} = await client.query({
			query: gql`
        {
          ${postTypePlural} {
            nodes {
              card {
                cardviewExcerpt
                cardviewHeading
                cardviewLinkText
                fieldGroupName
                cardviewFeaturedImage {
                  altText
                  mediaDetails {
                   height
                    width
                  }
                  sourceUrl
                }
              }
              uri
            }
          }
        }
      `,
		});
		return data[postTypePlural].nodes;
	} catch (e) {
		console.error(
			`[posts][getPostTypeCards] Failed to query category data: ${e.message}`,
		);
		throw e;
	}
};
