import {headerFields} from './fields/headerFields';
import {filterFields} from './fields/filterFields';
import {blogCardFields} from './fields/blogGridFields';
import {ViewMoreProjectsFields} from './fields/viewMoreProjectsFields';
import {ctaBannerFields} from './fields/ctaBannerFields';
import {footerFields} from './fields/footerFields';
import {postHeaderFields} from './fields/postHeaderFields';
import {postText} from './fields/PostText';
import {blogAuthor} from './fields/blogAuthor';
import {imagesFields} from './fields/imagesFields';
import {keepReadingFields} from './fields/keepReadingFields';
import {WYSIWYG} from './fields/WYSIWYG';
import {TextAndImage} from './fields/textAndImage';
import {StatAndImage} from './fields/statAndImage';
import {statsGrid} from './fields/statsGrid';
import {imagesGridFields} from './fields/imagesGrid';
import {ExpertieseFields} from './fields/expertiseFields';
import {TestimonialFields} from './fields/testimonialFields';
import {CustomCards} from './fields/customCards';
import {Highlights} from './fields/highlights';
import {ServicesFields} from './fields/serviceFields';
import {ServiceGroupFields} from './fields/servicesGroupFields';
import {HomePageHeaderFields} from './fields/homePageHeaderFields';
import {ScrollableTabsFields} from './fields/scrollableTabsFields';
import {TitleWithImage} from './fields/titleWithImage';
import {headerWithCalendar} from './fields/headerCalendar';
import {locationGrid} from './fields/locationGrid';
import {exploreButton} from './fields/exploreButton';
import {ProjectsGrid} from './fields/projectsGrid';
import {mainMenu} from './fields/mainMenu';
import {WYSIWYGVideo} from './fields/WYSIWYSVideo';
import {sectionHeaderFields} from './fields/sectionHeaderFields';
import {relatedPostsFields} from './fields/relatedPostsFields';
import {servicesOverviewFields} from './fields/servicesOverviewFields';

export const getAcfPageLayoutFieldsQuery = (type: string) => {
	return `
    ${ServiceGroupFields(type)}
    ${HomePageHeaderFields(type)}
    ${headerWithCalendar(type)}
    ${ProjectsGrid(type)}
    ${mainMenu(type)}
    ${locationGrid(type)}
    ${exploreButton(type)}
    ${ScrollableTabsFields(type)}
    ${ServicesFields(type)}
    ${Highlights(type)}
    ${CustomCards(type)}
    ${TestimonialFields(type)}
    ${ExpertieseFields(type)}
    ${imagesGridFields(type)}
    ${statsGrid(type)}
    ${StatAndImage(type)}
    ${TextAndImage(type)}
    ${WYSIWYG(type)}
    ${WYSIWYGVideo(type)}
    ${keepReadingFields(type)}
    ${relatedPostsFields(type)}
    ${blogCardFields(type)}
    ${imagesFields(type)}
    ${TitleWithImage(type)}
    ${blogAuthor(type)}
    ${postText(type)}
    ${postHeaderFields(type)}
    ${headerFields(type)}
    ${sectionHeaderFields(type)}
    ${filterFields(type)}
    ${ViewMoreProjectsFields(type)}
    ${ctaBannerFields(type)}
    ${footerFields(type)}
    ${servicesOverviewFields(type)}
  `;
};

export const getPartialBackgroundFields = (fieldName = 'partialbackground') => `
  ${fieldName} {
      backgroundtype
      customcolour
      themebackgroundcolor
  }
`;

export const getImageFields = (fieldName = 'image') => `
  ${fieldName} {
    sourceUrl
    altText
    mediaDetails {
      height
      width
    }
  }
`;

export const getLinkFields = (fieldName = 'link') => `
  ${fieldName} {
      target
      title
      url
  }
`;
