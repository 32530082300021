/** @format */

// @ts-nocheck

import styles from './main-menu.module.scss';

import {Box, Flex, useColorMode} from '@chakra-ui/react';
import {MouseEventHandler, useEffect, useState, useRef} from 'react';
import Contacts from '../../molecules/contact/contact';
import Follow from '../../molecules/follow/follow';
import MainMenuNav from '../../molecules/main-menu-nav/main-menu-nav';
import Menu from '../../molecules/menu/menu';
import {useSelector} from 'react-redux';
import {menuState} from '../../../redux/slices/menuSlice';

interface MainMenuProps {
	onClick: MouseEventHandler<HTMLParagraphElement>;
}

const MainMenu = ({onClick}: MainMenuProps) => {
	const mainMenuNavRef = useRef();
	const {colorMode, toggleColorMode} = useColorMode();
	const [hideDiv, setHideDiv] = useState(false);
	const menuData = useSelector(menuState);

	useEffect(() => {
		const wraper = document.getElementsByClassName(styles.menuComponent);
		if (wraper != null) {
			setTimeout(() => {
				wraper[0].classList.add(styles.menuComponentOpen);
				const rightSide = document.getElementsByClassName(styles.rightSide);
				if (rightSide != null) {
					setTimeout(() => {
						rightSide[0].classList.add(styles.rightSideOpen);
					}, 1000);
				}
			}, 20);
		}
	}, []);

	const closeNavbar = () => {
		setHideDiv(true);
		const wraper = document.getElementsByClassName(styles.menuComponent);
		wraper[0].classList.remove(styles.menuComponentOpen);
		const rightSide = document.getElementsByClassName(styles.rightSide);
		rightSide[0].classList.remove(styles.rightSideOpen);
		setTimeout(() => {
			onClick();
		}, 1000);
	};

	return (
		<Box
			backgroundColor='#121D33'
			className={styles.menuComponent}
			zIndex='100'>
			<Box className='wrapper'>
				<Box className={styles.headerComponent}>
					<MainMenuNav ref={mainMenuNavRef} onClick={closeNavbar} />
				</Box>
				<Flex
					flexDirection={'column'}
					justifyContent='space-between'
					height='calc(100vh - 142px)'
					//height="100vh"
					position='relative'
					className={styles.footerWrapper}>
					<Box>
						<Flex
							justifyContent={'space-between'}
							paddingTop='108px'
							className={styles.contentWrapper}>
							{!hideDiv ? (
								<Flex className={hideDiv ? styles.hide : ''}>
									<Menu
										data={menuData.menuData?.mainMenuLinks}
										onClick={() => {
											mainMenuNavRef.current.closeNavbar();
										}}
									/>
								</Flex>
							) : (
								<Flex></Flex>
							)}
							<Flex
								flexDirection={'column'}
								justifyContent='space-between'
								className={styles.rightSide}>
								<Box className={styles.contacts}>
									<Contacts contactsData={menuData.menuData?.contactDetails} />
								</Box>
								<Box className={styles.follow}>
									<Follow followData={menuData.menuData?.follow} />
								</Box>
							</Flex>
						</Flex>
					</Box>

					{/* {!hideDiv ? (
            <Box>
              <IconButton
                className={styles.toggleTheme}
                aria-label="Toggle Theme"
                marginBottom="55px"
                width={"50px"}
                icon={colorMode === "light" ? <SunIcon /> : <MoonIcon />}
                onClick={toggleColorMode}
              />
            </Box>
          ) : (
            <></>
          )} */}
				</Flex>
			</Box>
		</Box>
	);
};
export default MainMenu;
