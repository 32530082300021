/** @format */

import {Button, Flex, Text} from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';
import {colors} from '../../../styles/js/colors';
import styles from './services-group.module.scss';

interface ServicesGroupProps {
	post: {
		service: string;
		serviceHeroText: string;
		serviceDescription: string;
		readMoreLink: string;
		designColor: string;
		image: {
			mediaItemUrl: string;
			altText?: string;
		};
	};
}

export const ServicesGroup = ({post}: ServicesGroupProps) => {
	return (
		<Flex
			className={styles.sectionFlex}
			maxW={'100%'}
			background={`linear-gradient(90deg, ${colors.navy} 60%, ${post.designColor} 40%)`}>
			<Flex
				className={styles.servicesGroupWrapper}
				justifyContent='space-between'>
				<Flex
					flexDir={'column'}
					background={colors.navy}
					justify='center'
					maxW={'400px'}>
					<Text
						color={post.designColor ? post.designColor : 'white'}
						textStyle={'heading8Medium'}
						paddingBottom={'32px'}>
						{post.service}
					</Text>
					<Text
						color={'white'}
						textStyle={{
							base: 'heading5Medium',
							lg: 'heading3Medium',
						}}
						paddingBottom={'32px'}
						as='h2'>
						{post.serviceHeroText}
					</Text>
					<Text
						color={'white'}
						textStyle={'paragraphMDRegular'}
						paddingBottom={'32px'}>
						{post.serviceDescription}
					</Text>
					<Link href={`services/${post.readMoreLink}`}>
						<a>
							<Button variant={'transparent'}>Read more</Button>
						</a>
					</Link>
				</Flex>
				<Flex minW='330px' className={styles.servicesImage}>
					<Image
						src={post.image?.mediaItemUrl}
						alt={post.image?.altText}
						width={'768px'}
						height={'768px'}></Image>
				</Flex>
			</Flex>
		</Flex>
	);
};
