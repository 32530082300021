import React, { MouseEventHandler } from "react";
import { Text, Button, Box } from "@chakra-ui/react";
import { ArrowLeft } from "../../../../icons/arrow-left";
import { ArrowRight } from "../../../../icons/arrow-right";
import { colors } from "../../../../styles/js/colors";
import styles from "./css.module.scss";

interface DefaultButtonProps {
  text: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  width?: string;
  height?: string;
  fontSize?: string;
  variant?: string;
  leftIcon: boolean;
  rightIcon: boolean;
  icon: any;
  color?: string;
  textBaseStyle?: string;
  keepReading?: boolean;
}
const TextButtonArrowIcon = React.forwardRef(
  (
    {
      text,
      onClick,
      variant,
      leftIcon,
      rightIcon,
      color,
      textBaseStyle,
      keepReading,
    }: DefaultButtonProps,
    ref
  ) => {
    return (
      <Button
        padding={"0px 0px"}
        variant={variant}
        color={color}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        background={"transparent"}
        maxWidth={"500px"}
        onClick={onClick}
        //className={moveDir === "right" ? styles.testBack : styles.test}
      >
        {leftIcon && (
          <Box pr={"13px"}>
            <ArrowLeft width={"24px"} height={"24px"} stroke={colors.seafoam} />
          </Box>
        )}
        <Text
          textStyle={{ base: textBaseStyle }}
          className={keepReading ? styles.text : styles.textTwo}

          // className={variant === "keepReading" && styles.test}
        >
          {text}
        </Text>
        {rightIcon && (
          <Box pl={"13px"}>
            <ArrowRight
              width={"24px"}
              height={"24px"}
              stroke={colors.seafoam}
            />
          </Box>
        )}
      </Button>
    );
  }
);
export default TextButtonArrowIcon;
