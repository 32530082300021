import { Button } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { pushToSelectedFilters } from "../../../../redux/slices/filtersSlice";

interface DefaultButtonProps {
  filterName: string;
  fontSize: string;
  isSelected: boolean;
}
const FilterButton = ({
  filterName,
  fontSize,
  isSelected,
}: DefaultButtonProps) => {
  const dispatch = useDispatch();

  return (
    <Button
      flexShrink={0}
      width={"fit-content"}
      height={"32px"}
      borderRadius={"16px"}
      fontSize={fontSize}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      letterSpacing="-0.32px"
      fontWeight={"500"}
      color={isSelected ? "white" : "black"}
      backgroundColor={isSelected ? "#121D33" : "white"}
      _hover={{
        backgroundColor: isSelected ? "" : "#121D33",
        color: isSelected ? "" : "white",
      }}
      _focus={{
        backgroundColor: isSelected ? "#121D33" : "white",
        color: isSelected ? "white" : "black",
      }}
      _active={{ border: "2px solid #white" }}
      onClick={() => dispatch(pushToSelectedFilters(filterName))}
    >
      {filterName}
    </Button>
  );
};
export default FilterButton;
