/** @format */

import {AspectRatio, Box, Container} from '@chakra-ui/react';
interface WysiwygProps {
	post: {
		wysiwygvideo: string;
	};
}
export const WysiwygVideo = ({post}: WysiwygProps) => {
	return (
		<Container maxW='container.xl' mx='auto' className='wrapper'>
			<AspectRatio ratio={16 / 9}>
				<Box dangerouslySetInnerHTML={{__html: post.wysiwygvideo}} />
			</AspectRatio>
		</Container>
	);
};
