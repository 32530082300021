/** @format */

import {ArrowForwardIcon} from '@chakra-ui/icons';
import {AspectRatio, Box, Flex, Image, Text} from '@chakra-ui/react';
import Link from 'next/link';
import styles from './locations-grid.module.scss';

const LocationsGrid = ({data}) => {
	return (
		<Box
			className={styles.locationsWrapper}
			zIndex={1}
			position='relative'
			key={`${data.locationTitle}Key`}>
			<Text
				fontWeight='500'
				fontSize='40px'
				lineHeight='48px'
				letterSpacing='-0.8px'
				className={styles.locationTitle}>
				{data.locationTitle}
			</Text>
			<Flex
				flexDirection='row'
				justifyContent='space-between'
				flexWrap='nowrap'
				alignItems='flex-end'
				className={styles.locationCards}
				gap='56px'>
				{data.locations?.map((loc, index) => {
					const locations = loc.locationCard.address.split(',');
					return (
						<Flex flexDir='column' width='100%' key={`location${index}`}>
							<AspectRatio
								ratio={
									Number(loc.locationCard.locationAspectRatio.split(':')[0]) /
									Number(loc.locationCard.locationAspectRatio.split(':')[1])
								}>
								<Image
									src={loc.locationCard.image.link}
									alt={loc.locationCard.image.altText || 'location-image'}
									objectFit={'fill'}></Image>
							</AspectRatio>
							<Box>
								<Text
									paddingTop='32px'
									fontWeight='500'
									fontSize='32px'
									lineHeight='40px'
									letterSpacing='-0.64px'>
									{loc.locationCard.city}
								</Text>
								<Text
									paddingTop='16px'
									fontWeight='400'
									fontSize='20px'
									lineHeight='32px'
									letterSpacing='-0.2px'>
									{locations[0]} <br /> {locations[1]} <br />
									{locations[2]} <br />
								</Text>
							</Box>

							<Box>
								<Text
									className={styles.text}
									marginTop='32px'
									cursor={'pointer'}
									color='var(--chakra-colors-gray-800)'
									fontSize='20px'
									fontWeight='500'
									lineHeight='32px'
									letterSpacing='-0.4px'
									width={'fit-content'}
									onClick={() => {
										if (loc.locationCard.what3words) {
											window.open(
												`https://what3words.com${loc.locationCard.what3words.replace(
													'//',
													'',
												)}`,
											);
										}
									}}>
									{loc.locationCard.what3words}
								</Text>
							</Box>
							<Link
								href={
									loc.locationCard.directionLink
										? loc.locationCard.directionLink
										: ''
								}
								passHref>
								<a target='_blank' className={styles.direction}>
									Find us on Google Maps{' '}
									<ArrowForwardIcon className={styles.arrow} color='#59D8C9' />
								</a>
							</Link>
							<Box>
								<Text
									className={styles.text}
									marginTop='32px'
									cursor={'pointer'}
									color='var(--chakra-colors-gray-800)'
									fontSize='20px'
									fontWeight='500'
									lineHeight='32px'
									letterSpacing='-0.4px'
									width={'fit-content'}
									onClick={() => {
										if (loc.locationCard.phone) {
											window.open(
												`tel:${loc.locationCard.phone.replace('(0)', '')}`,
											);
										}
									}}>
									{loc.locationCard.phone}
								</Text>
							</Box>
						</Flex>
					);
				})}
			</Flex>
		</Box>
	);
};

export default LocationsGrid;
