import React from "react";
import { Box } from "@chakra-ui/react";

const InstagramIcon = () => {
  return (
    <Box
      width={"48px"}
      height={"48px"}
      borderRadius={"24px"}
      border={"2px solid white"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      cursor={"pointer"}
      _hover={{
        background: "white",
        path: {
          fill: "black",
        },
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0027 5.8454C8.59743 5.8454 5.85075 8.59208 5.85075 11.9973C5.85075 15.4026 8.59743 18.1492 12.0027 18.1492C15.4079 18.1492 18.1546 15.4026 18.1546 11.9973C18.1546 8.59208 15.4079 5.8454 12.0027 5.8454ZM12.0027 15.9969C9.80212 15.9969 8.00312 14.2032 8.00312 11.9973C8.00312 9.79141 9.79677 7.99777 12.0027 7.99777C14.2086 7.99777 16.0022 9.79141 16.0022 11.9973C16.0022 14.2032 14.2032 15.9969 12.0027 15.9969ZM19.8412 5.59375C19.8412 6.39152 19.1987 7.02867 18.4062 7.02867C17.6085 7.02867 16.9713 6.38617 16.9713 5.59375C16.9713 4.80134 17.6138 4.15884 18.4062 4.15884C19.1987 4.15884 19.8412 4.80134 19.8412 5.59375ZM23.9157 7.05008C23.8247 5.12794 23.3856 3.42532 21.9775 2.02253C20.5747 0.619743 18.8721 0.180703 16.9499 0.0843279C14.9689 -0.0281093 9.03112 -0.0281093 7.05008 0.0843279C5.1333 0.175349 3.43067 0.614389 2.02253 2.01718C0.614389 3.41997 0.180703 5.12259 0.0843279 7.04473C-0.0281093 9.02577 -0.0281093 14.9635 0.0843279 16.9446C0.175349 18.8667 0.614389 20.5693 2.02253 21.9721C3.43067 23.3749 5.12794 23.8139 7.05008 23.9103C9.03112 24.0228 14.9689 24.0228 16.9499 23.9103C18.8721 23.8193 20.5747 23.3803 21.9775 21.9721C23.3803 20.5693 23.8193 18.8667 23.9157 16.9446C24.0281 14.9635 24.0281 9.03112 23.9157 7.05008ZM21.3564 19.0702C20.9388 20.1196 20.1303 20.9281 19.0755 21.351C17.496 21.9775 13.7481 21.8329 12.0027 21.8329C10.2572 21.8329 6.50396 21.9721 4.92984 21.351C3.88042 20.9334 3.07195 20.1249 2.64897 19.0702C2.02253 17.4907 2.16709 13.7428 2.16709 11.9973C2.16709 10.2519 2.02789 6.49861 2.64897 4.92448C3.06659 3.87507 3.87507 3.06659 4.92984 2.64361C6.50931 2.01718 10.2572 2.16174 12.0027 2.16174C13.7481 2.16174 17.5014 2.02253 19.0755 2.64361C20.1249 3.06124 20.9334 3.86972 21.3564 4.92448C21.9828 6.50396 21.8383 10.2519 21.8383 11.9973C21.8383 13.7428 21.9828 17.496 21.3564 19.0702Z"
          fill="white"
        />
      </svg>
    </Box>
  );
};
export default InstagramIcon;
