/** @format */

import {
	AspectRatio,
	Box,
	Button,
	Flex,
	Grid,
	GridItem,
	Text,
} from '@chakra-ui/react';
import Image from 'next/image';
import styles from './services-overview.module.scss';

export const ServicesOverview = ({
	tagline,
	headerText,
	description,
	images,
	brandingList,
	readMoreLinkText,
	backgroundColor,
	brandColour,
	...props
}: any) => {
	// console.log('ServicesOverview:  ', brandingList);

	return (
		<Box className={styles.sectionFlex} maxW={'100%'} bg={backgroundColor}>
			<Box className={styles.servicesGroupWrapper} py={{base: '6rem'}}>
				<Text color={brandColour} textStyle={{base: 'heading8Medium'}}>
					{tagline}
				</Text>
				<Box pt={{base: '1rem'}} />
				<Text
					textStyle={{
						base: 'heading5Medium',
						lg: 'heading3Medium',
					}}
					as='h2'>
					{headerText}
				</Text>
				<Box pt={{base: '2rem'}} />

				<Grid
					templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)'}}
					gap={{base: '2rem', md: '3.5rem'}}>
					<GridItem colSpan={{base: 1, md: 2}}>
						<Box
							className={'WYSIWYG WYSIWYG--default'}
							dangerouslySetInnerHTML={description}></Box>
						<Button
							width={{base: '100%', md: 'auto'}}
							as='a'
							variant={'border'}
							target={readMoreLinkText.target}
							href={readMoreLinkText.url}>
							{readMoreLinkText.title}
						</Button>
					</GridItem>
					<GridItem colSpan={{base: 1, md: 1}}>
						{brandingList.map((item, i) => (
							<Box key={i}>
								<Flex align={{base: 'center'}}>
									<Box
										bg={brandColour}
										width={'2rem'}
										height={'0.125rem'}
										mr={{base: '0.75rem'}}></Box>
									<Text textStyle={{base: 'paragraphMDRegular'}}>
										{item.branding}
									</Text>
								</Flex>
							</Box>
						))}
					</GridItem>
				</Grid>

				<Box pt={{base: '2rem', md: '3.5rem'}} />

				<Grid
					templateColumns={{base: 'repeat(2, 1fr)'}}
					gap={{base: '1rem', md: '3.5rem'}}>
					{images.map((item, i) => (
						<AspectRatio ratio={4 / 3} key={i}>
							<Image
								src={item.image.sourceUrl}
								alt={item.image.altText}
								// width={item.image.mediaDetails.width}
								// height={item.image.mediaDetails.height}
								layout='fill'
								style={{
									width: '100%',
									height: 'auto',
									objectFit: 'contain',
								}}
							/>
						</AspectRatio>
					))}
				</Grid>
			</Box>
		</Box>
	);
};
