/** @format */

import styles from './big-blog-card.module.scss';
import Link from 'next/link';
import {Flex, Text, Image, AspectRatio} from '@chakra-ui/react';
import {colors} from '../../../styles/js/colors';
import {BlogCardDetails} from '../blog-card-details/blog-card-details';

interface BlogCardProps {
	cardData: {
		postType: string;
		slug: string;
		heroImage: {link: string; altText: string};
		heroText: string;
		heroDescription: string;
		blogType: string;
		date: string;
	};
}
export const BigBlogCard = ({cardData}) => {
	return (
		<Link
			href={`${
				cardData.postType === 'Projects' ? '/projects/' : '/news-insights/'
			}${cardData.slug}`}>
			<a>
				<Flex
					cursor={'pointer'}
					flexDirection={'column'}
					maxWidth={'100%'}
					justifyContent={'center'}
					_hover={{
						'.card-title': {color: colors.spruce},
						'.type': {backgroundColor: colors.stone},
						img: {
							transform: 'scale(0.93)',
						},
					}}>
					<AspectRatio ratio={3 / 2}>
						<Image
							alt={cardData?.heroImage?.altText || 'blog-card-image'}
							className={styles.image}
							src={cardData?.heroImage?.link}
							width={'629x'}
							objectFit={'fill'}
							transition={'all 0.3s ease-in-out'}></Image>
					</AspectRatio>
					{/* this Flex and the divs bellow are needed to make a specific layout */}
					<Flex
						flexDir={'column'}
						justifyContent={'space-between'}
						height={'100%'}>
						<div>
							<Text
								className={`${styles.cardTitle} card-title`}
								textStyle={{base: 'heading7Medium', sm: 'heading6Medium'}}
								paddingTop={6}
								paddingBottom={6}
								as='h3'>
								{cardData.heroText}
							</Text>
							{cardData.postType === 'Projects' && (
								<Text textStyle={{base: 'paragraphMDRegular'}}>
									{cardData.heroDescription}
								</Text>
							)}
							{cardData.postType !== 'Projects' && (
								<Flex flexDirection='row'>
									{cardData.blogType?.length &&
										cardData.blogType.map((blogType: string) => {
											return (
												blogType !== 'None' && (
													<BlogCardDetails type={blogType} />
												)
											);
										})}
									<Text
										letterSpacing={'-0.16px'}
										width={'fit-content'}
										fontWeight={'400'}
										padding={'4px 16px'}>
										{cardData.date}
									</Text>
								</Flex>
							)}
						</div>
						<div> </div>
					</Flex>
				</Flex>
			</a>
		</Link>
	);
};
