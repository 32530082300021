import { Box, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { colors } from "../../../styles/js/colors";

interface BlogCardDetailsProps {
  type: string;
}

const getBadgeColor = (type: string) => {
let typeBackgroundColor = "";
  switch (type.toLowerCase()) {
    case "news":
      typeBackgroundColor = colors.lime;
      break;
    case "culture":
      typeBackgroundColor = colors.maple;
      break;
    case "insight":
      typeBackgroundColor = colors.honey;
      break;
    case "engagement":
      typeBackgroundColor = colors.honey;
      break;

    default:
      typeBackgroundColor = colors.lime;
      break;
  }

  return typeBackgroundColor;
};

export const BlogCardDetails = ({ type }: BlogCardDetailsProps) => {

  const [typeBackgroundColor, setTypeBackgroundColor] = useState<string>();

  useEffect(() => {
    const color = getBadgeColor(type);
    setTypeBackgroundColor(color);
  }, []);


  return (
    <Flex alignItems={"center"}>
      <Box
        className="type"
        letterSpacing={"-0.32px"}
        gap="10px"
        borderRadius={"16px"}
        fontWeight={"500"}
        backgroundColor={typeBackgroundColor}
        color="black"
        padding={"4px 16px"}
        marginRight={"8px"}
      >
        {type.charAt(0).toUpperCase() + type.slice(1)}
      </Box>
      <Box marginRight={"8px"}>
        <span>&#8226;</span>
      </Box>
    </Flex>
  );
};
