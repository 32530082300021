import styles from './footer.module.scss';
import {Box, Flex, Text} from '@chakra-ui/react';
//custom components
import Menu from '../../molecules/menu/menu';
import Contacts from '../../molecules/contact/contact';
import Follow from '../../molecules/follow/follow';
import Link from 'next/link';
interface FooterProps {
	footerData: {
		contactDetails: {email: string; phoneNumber: string};
		follow: Array<{socialLinks: {title: string; url: string}}>;
		footerMenuLinks: Array<{link: {title: string; url: string}}>;
		policies: Array<{policy: string}>;
	};
}
const Footer = ({footerData}: FooterProps) => {
	return (
		<Flex
			paddingTop='96px'
			paddingBottom='46px'
			backgroundColor={'#121D33'}
			className={styles.footerWrapper}
			flexDirection={'column'}>
			<Flex
				justifyContent={'space-between'}
				className={styles.contentWrapper}
				flexDirection={'column'}>
				<Flex
					justifyContent={'space-between'}
					className={styles.mainContentWrapper}>
					<Menu data={footerData?.footerMenuLinks} />
					<Flex
						className={styles.rightSide}
						flexDirection={'column'}
						width={'400px'}>
						<Box className={styles.contacts}>
							<Contacts contactsData={footerData.contactDetails} />
						</Box>
						<Box className={styles.follow} paddingTop={'96px'} maxW='300px'>
							<Follow followData={footerData.follow} />
						</Box>
					</Flex>
				</Flex>

				<Flex
					paddingTop={'72px'}
					justifyContent={'space-between'}
					className={styles.policies}>
					<Flex color={'white'} flexDirection={'column'} width={'100%'}>
						<Link href={'/privacy-policy'}>
							<a>
								<Text
									className={styles.text}
									height={'24px'}
									width={'fit-content'}
									marginBottom={'16px'}
									fontWeight={'500'}
									letterSpacing={'-0.32px'}
									cursor={'pointer'}
									key={footerData.policies[0].policy}>
									{footerData.policies[0].policy}
								</Text>
							</a>
						</Link>
						<Flex justifyContent={'space-between'}>
							<Link href={'/cookie-policy'}>
								<a>
									<Text
										className={styles.text}
										fontWeight={'500'}
										letterSpacing={'-0.32px'}
										height={'24px'}
										key={
											footerData.policies[1] ? footerData.policies[1].policy : ''
										}
										cursor={'pointer'}>
										{footerData.policies[1]?.policy}
									</Text>
								</a>
							</Link>
							<Text
								className={styles.copyright}
								width={'400px'}
								textAlign={'left'}
								color={'white'}
								fontWeight={'400'}
								letterSpacing={'-0.16px'}>
								{`© ${new Date().getFullYear()} Bulb Studios Ltd`}
							</Text>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
};
export default Footer;
