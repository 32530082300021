import styles from "./cta-banner.module.scss";
import Link from "next/link";
import { Flex, Text, useColorMode } from "@chakra-ui/react";
import { colors } from "../../../styles/js/colors";
//custom components
import CustomIconButton from "../../atoms/buttons/icon-button/icon-button";
import { SmileyIcon } from "../../atoms/buttons/let's-talk-button/smiley-icon";

interface BannerDataProps {
  post: {
    buttonText: string;
    heroText: string;
    letsTalkText: [{ sentence: string }];
  };
}
export const CTABanner = ({ post }: BannerDataProps) => {
  const { colorMode } = useColorMode();

  return (
    <Flex
      //id is used for showing / hiding the lets talk button
      id="CTABanner"
      className={styles.ctaContainer}
      height={"624px"}
      background={
        colorMode === "light"
          ? "linear-gradient(180deg, #F2F3F5 50%, #121D33 50%)"
          : colors.navy
      }
    >
      <Flex className="wrapper">
        <Flex
          className={styles.ctaContentWrapper}
          width={"100%"}
          height={"100%"}
          borderRadius={"32px"}
          backgroundColor={"#59D8C9"}
          alignItems={"center"}
          justifyContent={"space-evenly"}
        >
          <Text
            maxWidth={"514px"}
            className={styles.heroText}
            textStyle={{ base: "heading3Medium" }}
            color={colors.navy}
            as='h3'
          >
            {post.heroText}
          </Text>
          <Flex
            className={styles.sentenceWrapper}
            flexDirection={"column"}
            width={"400px"}
            paddingTop={"128px"}
            paddingBottom={"128px"}
          >
            {post.letsTalkText?.map((sentence: { sentence: string }) => {
              return (
                <Text
                  className={styles.sentenceText}
                  color={colors.navy}
                  textStyle={{ base: "paragraphMDRegular" }}
                  paddingBottom={"32px"}
                  key={sentence.sentence}
                >
                  {sentence.sentence}
                </Text>
              );
            })}
            <Link href={"/contact"} passHref>
              <a>
                <CustomIconButton
                  variant={"letsTalk"}
                  text={"Let's go"}
                  rightIcon={true}
                  leftIcon={false}
                  icon={<SmileyIcon />}
                />
              </a>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
