import { useEffect, useState } from "react";
import { Box, Flex, Heading, Text, useColorMode } from "@chakra-ui/react";

import { getNewsInsights, getProjects } from "../../../lib/api/posts";
import styles from "./project-grid.module.scss";
import { ProjectCardDataFormater } from "../../../lib/projectCardsDataFormater";
import { BlogCard, SkeletonBlogCard } from "../../molecules/blog-card/blog-card";
import { BigBlogCard } from "../../molecules/big-blog-card/big-blog-card";
import { NewsAndInsightsCardsDataFormater } from "../../../lib/newsAndInsightsCardsDataFormater";

const ProjectGrid = (post) => {
  const [blogCards, setBlogCards] = useState([]);
  const [bigBlogCard, setBigBlogCard] = useState([]);
  const { colorMode } = useColorMode();
  const [cardsLoading, setCardsLoading] = useState(true);
  const placeholderCardsCount = 10;
  //simplify the prop data to an array
  const blogsToShow = post?.data.blogCards?.map((card) => {
    return card.title;
  });
  const bigBlog = post?.data.bigBlogCard.title;
  useEffect(() => {
    // fetches the blogs
    if (post.blogCards?.__typename === "NewsInsights") {
      getNewsInsights().then((data) => {
        //formats the fetched data
        const tempBlogCards = NewsAndInsightsCardsDataFormater(data.data);
        const filteredBlogCards = [];
        tempBlogCards.blogCards.filter((blogCard) => {
          blogsToShow.includes(blogCard.heroText) &&
            filteredBlogCards.push(blogCard);
        });
        setBlogCards(filteredBlogCards);
        setCardsLoading(false);
      });
    } else {
      getProjects().then((data) => {
        //formats the fetched data
        const tempBlogCards = ProjectCardDataFormater(data.data);
        const filteredBlogCards = [];
        const bigBlogCard = [];
        tempBlogCards.blogCards.filter((blogCard) => {
          bigBlog === blogCard.heroText && bigBlogCard.push(blogCard);
        });
        blogsToShow.forEach((blog) => {
          tempBlogCards.blogCards.forEach((blogCard) => {
            if (
              blogCard.heroText === blog &&
              !bigBlog.includes(blogCard.heroText)
            ) {
              filteredBlogCards.push(blogCard);
              return;
            }
          });
        });
        setBlogCards(filteredBlogCards);
        setBigBlogCard(bigBlogCard);
        setCardsLoading(false);
      });
    }
  }, []);
  return (
    <Box>
      <Flex
        className={`${styles.contentWrapper} wrapper`}
        padding={"128px 64px 96px 64px"}
        flexDir="column"
      >
        <Flex
          className={styles.headerWrapper}
          justifyContent={"space-between"}
          alignItems="center"
          width="100%"
        >
          <Heading paddingLeft="150px" textStyle={{ base: "heading5Medium" }} as='h3'>
            {post.title}
          </Heading>
        </Flex>

        <Box
          mb={{ base: "80px", md: "0px" }}
        >
          <Text
            fontSize="40px"
            fontWeight="500"
            lineHeight="48px"
            letterSpacing="-0.8px"
            maxWidth="628px"
            marginBottom="56px"
          >
            {post.data.title}
          </Text>
          {bigBlogCard &&
            bigBlogCard?.map((bigCard) => (
              <BigBlogCard key={bigCard.heroText} cardData={bigCard} />
            ))}
        </Box>
        <Box
          className={styles.cardsGrid}
          display={"grid"}
          gridTemplateColumns="1fr 1fr"
          gap={"56px"}
          padding="44px 0px"
          alignSelf={"center"}
          justifySelf={"center"}
        >
          {blogCards &&
            blogCards?.map((card) => {
              return <BlogCard key={card.heroText} cardData={card}></BlogCard>;
            })}
            {cardsLoading && (
              placeholderCardsCount &&
                [...Array(placeholderCardsCount)].map((_, i) => (
                  <SkeletonBlogCard key={i} />
                ))
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default ProjectGrid;
