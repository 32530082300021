/** @format */

import React from 'react';
import Head from 'next/head';
import {ReactNode} from 'react';
import {useRouter} from 'next/router';

type Props = {
	children?: ReactNode;
	title?: string;
	seo?: any;
};

const Layout = ({children, seo}: Props) => {
	const router = useRouter();
	// const canonicalUrl = `${process.env.NEXT_PUBLIC_APP_URL}${router?.asPath}`;
	const canonicalUrl = (
		`${process.env.NEXT_PUBLIC_APP_URL}` +
		(router.asPath === '/' ? '' : router.asPath)
	).split('?')[0];

	return (
		<>
			<Head>
				<title>
					{seo?.title
						? seo.title
						: 'Bulb Studios | Pioneering Digital Products'}
				</title>
				<meta charSet='utf-8' />
				<meta httpEquiv='Content-Type' content='text/html; charset=utf-8' />
				<meta name='viewport' content='initial-scale=1.0, width=device-width' />
				<link rel='canonical' href={canonicalUrl} />
			</Head>
			{children}
		</>
	);
};
export default Layout;
