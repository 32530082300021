import { Flex, Text } from "@chakra-ui/react";

interface LabelProps {
  labelText: string;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
  color?: string;
  letterSpacing?: string;
}
const LineLabel = ({
  labelText,
  fontSize,
  fontWeight,
  lineHeight,
  color,
  letterSpacing,
}: LabelProps) => {
  return (
    <Flex
      alignItems={"center"}
      justifyContent={"center"}
      fontWeight={"500"}
      fontSize={"20px"}
      lineHeight={"32px"}
      width={"fit-content"}
    >
      <Text
        fontSize={fontSize}
        fontWeight={fontWeight}
        lineHeight={lineHeight}
        color={color}
        letterSpacing={letterSpacing}
      >
        {labelText}
      </Text>
      <div
        style={{
          borderBottom: "2px solid #D4E157",
          width: "64px",
          marginLeft: "16px",
        }}
      ></div>
    </Flex>
  );
};
export default LineLabel;
