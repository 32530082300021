/** @format */

import styles from './highlights.module.scss';
import {Flex, Text, Grid} from '@chakra-ui/react';
import {Highlight} from '../../molecules/highlight/highlight';

interface HighlightsProps {
	post: {
		backgroundColor: string;
		textColor: string;
		title: string;
		highlights: {
			highlight: [
				{
					svg: {link: string; altText: string};
					blockTitle: string;
					blockDescription: string;
				},
			];
		};
	};
}

export const Highlights = ({post}: HighlightsProps) => {
	return (
		<Flex backgroundColor={post.backgroundColor} color={'white'}>
			<Flex className={styles.highlightWrapper} flexDir={'column'}>
				<Text
					className={styles.title}
					textStyle={{base: 'heading5Medium'}}
					paddingBottom='64px'
					color={post.textColor}
					as='h3'>
					{post.title}
				</Text>
				<Grid
					className={styles.grid}
					justifyContent={'space-between'}
					gridTemplateColumns='1fr 1fr'>
					{post.highlights.highlight?.map((highlight, index) => {
						return (
							<Flex justifyContent={index % 2 ? 'flex-end' : 'flex-start'}>
								<div key={highlight.blockTitle}>
									<Highlight highlightData={highlight} color={post.textColor} />
								</div>
							</Flex>
						);
					})}
				</Grid>
			</Flex>
		</Flex>
	);
};
