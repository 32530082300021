/** @format */

export class BlogCardProps {
	title: string;
	postType: string;
	projectsLayout: {category: Array<{name: string}>};
	servicesLayout: {category: Array<{name: string}>};
	newsAndInsightsLayout: {category: Array<{name: string}>};
	otherLayout: {};
	slug: string;
	heroImage: {link: string; altText: string};
	heroText: string;
	heroDescription: string;
	date: string;
	cardView: {
		title: string;
		excerpt: string;
		thumbnail: {
			sourceUrl: string;
		};
	};

	constructor() {
		(this.title = ''),
			(this.postType = ''),
			(this.projectsLayout = {category: new Array<{name: string}>()}),
			(this.servicesLayout = {category: new Array<{name: string}>()}),
			(this.newsAndInsightsLayout = {category: new Array<{name: string}>()}),
			(this.otherLayout = {}),
			(this.date = ''),
			(this.heroText = ''),
			(this.heroDescription = ''),
			(this.heroImage = {link: '', altText: ''}),
			(this.slug = '');
		this.cardView = {
			title: '',
			excerpt: '',
			thumbnail: {
				sourceUrl: '',
			},
		};
	}
}
