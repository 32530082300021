import { useState } from "react";
import styles from "./header-with-calendar.module.scss";
import Link from "next/link";
import { Box, Button, Flex, Text, useBreakpointValue, useColorMode } from "@chakra-ui/react";
//custom components
import LineLabel from "../../atoms/labels/label-with-line/line-label";
import MainMenu from "../main-menu/main-menu";
//svg's
import { BulbLogo } from "../../molecules/main-menu-nav/bulb-logo";
import CustomIconButton from "../../atoms/buttons/icon-button/icon-button";
import { fonts } from "../../../styles/js/fonts";
import { PopupButton } from "react-calendly";
import { HeaderHeroText } from "../../../interfaces";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { colors } from "../../../styles/js/colors";
import { textStyles } from "../../../styles/js/textStyles";

interface HeaderProps {
  data: {
    headerText: string;
    textUnderHeader: string;
  };
}

const HeaderWithCalendar = ({ data }: HeaderProps) => {
  const { colorMode } = useColorMode();

  const [showMainMenu, setShowMainMenu] = useState(false);
  function _handleOnClick() {
    setShowMainMenu(!showMainMenu);
  }

  const headingStyle = useBreakpointValue(
    {
      base: textStyles.heading4Medium,
      md: textStyles.heading2Medium,
      lg: textStyles.heading1Medium
  }, 'base');

  return (
    <>
      {showMainMenu && <MainMenu onClick={_handleOnClick} />}
      <Box
        backgroundColor='honey'
        minH='100vh'
      >
        <Flex
          alignItems={"center"}
          justifyContent={"space-between"}
          py={{base: 6, md: 10}}
          px={{base: 6, md: 16}}
        >
          <Link href={"/"}>
            <a>
              <Box cursor={"pointer"}>
                <BulbLogo fill={"#121D33"} />
              </Box>
            </a>
          </Link>
          <CustomIconButton
            variant="outline"
            text={"Menu"}
            rightIcon={true}
            leftIcon={false}
            icon={<div className={styles.burger}></div>}
            onClick={_handleOnClick}
            className={styles.button}
          />
        </Flex>
        <Flex
          flexDirection={"column"}
          pt={{base: '112px', lg: '192px'}}
          pb='128px'
          px={{base: 6, md: 8, lg: 16}}
          gap={12}
        >
          <Flex
            direction='column'
            gap={8}
          >
            <Text as='h1' style={headingStyle} maxW='856px'>
              {data.headerText}
            </Text>
            <Box
              textStyle='paragraphLG'
              width="66.67%"
              dangerouslySetInnerHTML={{ __html: data.textUnderHeader }}
            />
          </Flex>
          <Flex flexDirection={{base: 'column', md: 'row'}} gap={6} alignItems='flex-start'>
              <Flex style={{ display: "none" }}>
                <PopupButton
                  url="https://calendly.com/bulbstudios"
                  rootElement={
                    typeof window !== "undefined"
                      ? document.getElementById("__next")
                      : null
                  }
                  text="Book a free call"
                  className="calendlyButton"
                />
              </Flex>
              <Button
                variant="solid"
                colorScheme="navy"
                padding="12px, 24px, 12px, 24px"
                maxWidth="190px"
                color={"navy"}
                backgroundColor="navy"
                _hover={{
                  backgroundColor: "white",
                  color: "navy",
                  path: { fill: "navy" },
                }}
                _focus={{ backgroundColor: "navy", color: "white", path: { fill: "white" } }}
                _active={{ border: "2px solid #59D8C9" }}
                onClick={() => {
                  const button: HTMLElement = document.getElementsByClassName(
                    "calendlyButton"
                  )[0] as HTMLElement;
                  button.click();
                }}
                textColor='white'
              >
                Book a free call
                <ArrowForwardIcon fontSize="25px" marginLeft="5px" />
              </Button>
              <Button
                // variant="outline"
                colorScheme="navy"
                padding="12px, 24px, 12px, 24px"
                maxWidth="141px"
                color="navy"
                border='2px solid #121D33'
                _hover={{
                  backgroundColor: "#121D33",
                  color: "white",
                  path: { fill: "white" },
                }}
                _focus={{ backgroundColor: "#white", color: "navy" }}
                _active={{ border: "2px solid #121D33" }}
                onClick={() => {
                  window.location.href = `mailto:info@bulbstudios.com`;
                }}
              >
                Email us
                <ArrowForwardIcon fontSize="25px" marginLeft="5px" />
              </Button>
            </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default HeaderWithCalendar;
