/** @format */

import styles from './blog-card.module.scss';
import Link from 'next/link';
import {
	Flex,
	Text,
	AspectRatio,
	LinkBox,
	Skeleton,
	SkeletonText,
} from '@chakra-ui/react';
import Image from 'next/image';
import {colors} from '../../../styles/js/colors';
import {BlogCardDetails} from '../blog-card-details/blog-card-details';
import {useRouter} from 'next/router';
import {RelatedPostCard} from '../../organisms/related-posts';

interface BlogCardProps {
	cardData: {
		postType: string;
		projectsLayout: {category: Array<{name: string}>};
		servicesLayout: {category: Array<{name: string}>};
		newsAndInsightsLayout: {category: Array<{name: string}>};
		otherLayout: {};
		slug: string;
		heroImage: {link: string};
		heroText: string;
		heroDescription: string;
		date: string;
		cardView: {
			title?: string;
			excerpt?: string;
			thumbnail?: {
				altText?: string;
				sourceUrl: string;
			};
		};
	};
}

type FormattedPostCard = {
	cardData: RelatedPostCard;
};

export const BlogCard = (cardData: BlogCardProps) => {
	let relatedPostCard;
	const router = useRouter();
	let url: string;
	//mapping url as is non-standard case
	if (router.asPath === '/news-insights') {
		url = `news-insights/${cardData.cardData.slug}`;
	} else if (
		cardData?.cardData?.postType !== undefined &&
		cardData?.cardData?.postType !== 'News & Insights'
	) {
		url = `/${cardData?.cardData?.postType?.toLowerCase()}/${
			cardData?.cardData?.slug
		}`;
	} else if (
		cardData?.cardData?.postType !== undefined &&
		cardData?.cardData?.postType === 'News & Insights'
	) {
		url = `news-insights/${cardData.cardData.slug}`;
	} else {
		relatedPostCard = cardData as unknown as FormattedPostCard;
		url = relatedPostCard.cardData.uri;
	}

	const hasLayout =
		relatedPostCard?.cardData?.pageLayout?.flexibleContent[0]?.projectsLayout ||
		relatedPostCard?.cardData?.pageLayout?.flexibleContent[0]?.servicesLayout ||
		relatedPostCard?.cardData?.pageLayout?.flexibleContent[0]
			?.newsAndInsightsLayout;

	const isProjectLayout =
		relatedPostCard?.cardData?.pageLayout?.flexibleContent[0]?.projectsLayout;
	const isServicesLayout =
		relatedPostCard?.cardData?.pageLayout?.flexibleContent[0]?.servicesLayout;
	const isNewsInsightsLayout =
		relatedPostCard?.cardData?.pageLayout?.flexibleContent[0]
			?.newsAndInsightsLayout;

	const formattedDate = new Date(
		relatedPostCard?.cardData?.date,
	).toLocaleDateString('en-US', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	});

	return (
		<LinkBox as={Link} href={url || ''}>
			<a>
				<Flex
					cursor={'pointer'}
					flexDirection={'column'}
					justifyContent={'space-between'}
					_hover={{
						'.card-title': {color: colors.spruce},
						img: {
							transform: 'scale(0.93)',
						},
						'.card-image': {
							cursor: 'pointer',
						},
					}}>
					<AspectRatio ratio={3 / 2}>
						<Image
							className={styles.image}
							src={
								cardData?.cardData?.cardView?.thumbnail?.sourceUrl ||
								cardData?.cardData.heroImage?.link ||
								relatedPostCard?.cardData.featuredImage?.node?.sourceUrl ||
								relatedPostCard.cardData?.pageLayout?.flexibleContent[0]
									?.heroImage?.sourceUrl ||
								''
							}
							alt={
								relatedPostCard?.cardData?.pageLayout?.flexibleContent[0]
									?.heroImage?.altText ||
								cardData?.cardData?.cardView?.thumbnail?.altText ||
								'project-card-image'
							}
							layout='fill'
							objectFit='cover'
							style={{
								transition: 'all 0.3s ease-in-out',
							}}
							priority={true}
							quality={50}
						/>
					</AspectRatio>
					{/* this Flex and the divs bellow are needed to make a specific layout */}
					<Flex flexDir={'column'} height={'100%'}>
						<div>
							<Text
								className={`${styles.cardTitle} card-title`}
								textStyle={{base: 'heading7Medium', sm: 'heading6Medium'}}
								paddingTop={6}
								paddingBottom={6}
								as='h3'>
								{cardData?.cardData?.cardView?.title ||
									cardData.cardData.heroText ||
									relatedPostCard?.cardData.title}
							</Text>
							<Text textStyle={{base: 'paragraphMDRegular'}} pb={6}>
								{cardData?.cardData?.cardView?.excerpt ||
									cardData.cardData.heroDescription}
							</Text>
							{cardData.cardData.postType !== 'Projects' && (
								<Flex flexDirection='row' justifySelf='end'>
									{cardData.cardData.postType === 'Services' &&
										cardData.cardData.servicesLayout.category?.length &&
										cardData.cardData.servicesLayout.category.map(category => {
											return (
												<Flex key={category.name}>
													<BlogCardDetails type={category.name} />
												</Flex>
											);
										})}
									{cardData.cardData.postType === 'News & Insights' &&
										cardData.cardData.newsAndInsightsLayout.category?.length &&
										cardData.cardData.newsAndInsightsLayout.category.map(
											category => {
												return (
													<Flex key={category.name}>
														<BlogCardDetails type={category.name} />
													</Flex>
												);
											},
										)}
									{hasLayout && (
										<Flex flexDirection='row' justifySelf='end'>
											{isProjectLayout &&
												relatedPostCard?.cardData?.pageLayout?.flexibleContent[0]?.projectsLayout?.category?.map(
													category => (
														<Flex key={category.name}>
															<BlogCardDetails type={category.name} />
														</Flex>
													),
												)}
											{isServicesLayout &&
												relatedPostCard?.cardData?.pageLayout?.flexibleContent[0]?.servicesLayout?.category?.map(
													category => (
														<Flex key={category.name}>
															<BlogCardDetails type={category.name} />
														</Flex>
													),
												)}
											{isNewsInsightsLayout &&
												relatedPostCard?.cardData?.pageLayout?.flexibleContent[0]?.newsAndInsightsLayout?.category?.map(
													category => (
														<Flex key={category.name}>
															<BlogCardDetails type={category.name} />
														</Flex>
													),
												)}
										</Flex>
									)}
									<Text
										letterSpacing={'-0.16px'}
										width={'fit-content'}
										fontWeight={'400'}
										py={1}>
										{(formattedDate !== 'Invalid Date' && formattedDate) ||
											cardData.cardData.date}
									</Text>
								</Flex>
							)}
						</div>
						<div> </div>
					</Flex>
				</Flex>
			</a>
		</LinkBox>
	);
};

export const SkeletonBlogCard = () => {
	return (
		<LinkBox as='a' href='#'>
			<Flex
				cursor='pointer'
				flexDirection='column'
				justifyContent='space-between'
				_hover={{
					'.card-title': {color: 'gray'},
					img: {
						transform: 'scale(0.93)',
					},
					'.card-image': {
						cursor: 'pointer',
					},
				}}>
				<AspectRatio ratio={3 / 2}>
					<Skeleton isLoaded={false} />
				</AspectRatio>
				<Flex flexDir='column' height='100%'>
					<div>
						<Skeleton height='20px' my='10px' isLoaded={false} />
						<Skeleton height='20px' my='10px' isLoaded={false} />
						<Skeleton height='20px' my='10px' isLoaded={false} />
					</div>
					<div> </div>
				</Flex>
			</Flex>
		</LinkBox>
	);
};
