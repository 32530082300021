import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Button, Flex } from "@chakra-ui/react";
import Link from "next/link";

const ExploreButton = ({ data }) => {
  return (
    <Flex justifyContent="center" padding="40px 0px 40px 0px">
      <Link href={data.button.link !== null ? data.button.link?.uri : ""}>
        <a>
          <Button
            variant="outline"
            colorScheme="black"
            padding="12px, 24px, 12px, 24px"
            maxWidth="228px"
            textColor={data.darkBackground ? "#ffffff" : ""}
            _hover={{
              backgroundColor: "#121D33",
              color: "white",
              path: { fill: "white" },
            }}
            _focus={{ backgroundColor: "#white", color: "black" }}
            _active={{ border: "2px solid #59D8C9" }}
          >
            {data.button.buttonText}
            <ArrowForwardIcon fontSize="25px" marginLeft="5px" />
          </Button>
        </a>
      </Link>
    </Flex>
  );
};

export default ExploreButton;
