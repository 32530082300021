import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import Link from "next/link";
import CustomIconButton from "../../atoms/buttons/icon-button/icon-button";
import { BulbLogo } from "./bulb-logo";
import styles from "./main-menu-nav.module.scss";

interface MainMenuNavProps {
  onClick: Function;
}
const MainMenuNav = forwardRef(({ onClick }: MainMenuNavProps, ref) => {
  const [hideButton, setHideButton] = useState(false);

  useImperativeHandle(ref, () => ({
    closeNavbar() {
      closeNavbar();
    },
  }));

  useEffect(() => {
    const button = document.getElementsByClassName(styles.buttonMenu);
    if (button != null) {
      setTimeout(() => {
        button[0]?.classList.add(styles.open);
      }, 20);
    }
  });

  const closeNavbar = () => {
    const button = document.getElementsByClassName(styles.buttonMenu);
    button[0].classList.remove(styles.open);
    setTimeout(() => {
      setHideButton(true);

      onClick();
    }, 500);
  };

  return (
    <Flex
      className={styles.menuNav}
      justifyContent={"space-between"}
      alignItems="center"
    >
      <Link href={"/"}>
          <Box cursor={"pointer"}>
            <BulbLogo fill="white" width={"30.14px"} height={"40px"} />
          </Box>
      </Link>
      {!hideButton && (
        <CustomIconButton
          variant="transparent"
          text={"Menu"}
          rightIcon={true}
          leftIcon={false}
          icon={<div className={styles.burgerMenu}></div>}
          onClick={() => closeNavbar()}
          className={styles.buttonMenu}
        />
      )}
    </Flex>
  );
});
export default MainMenuNav;
