import { Box, Button, Flex, Text } from "@chakra-ui/react";
import styles from "./scrollable-tabs.module.scss";
import { fonts } from "../../../styles/js/fonts";
import { useEffect, useState } from "react";
import Link from "next/link";
import { ArrowForwardIcon } from "@chakra-ui/icons";

const ScrollableTabs = ({ data }) => {
  const [active, setActive] = useState("Strategy");
  const [scrollPosition, setScrollPosition] = useState(0);
  const [windowWidth, setWindowWidth] = useState(1200);

  /**
   * Change selected item depending on the page scroll position
   */
  const handleScroll = () => {
    const position = window.pageYOffset;
    if (windowWidth <= 1200) {
      if (position <= 1993) {
        setActive(data.tab[0]?.leftSideText);
      } else if (position >= 1993 && position <= 2234) {
        setActive(data.tab[1]?.leftSideText);
      } else if (position >= 2234 && position <= 2546) {
        setActive(data.tab[2]?.leftSideText);
      } else if (position >= 2546) {
        setActive(data.tab[3]?.leftSideText);
      }
    } else {
      if (position <= 1954) {
        setActive(data.tab[0]?.leftSideText);
      } else if (position >= 1954 && position <= 2118) {
        setActive(data.tab[1]?.leftSideText);
      } else if (position >= 2118 && position <= 2299) {
        setActive(data.tab[2]?.leftSideText);
      } else if (position >= 2299) {
        setActive(data.tab[3]?.leftSideText);
      }
    }

    setScrollPosition(position);
  };

  const handleClickOnLeftSide = (tab, index) => {
    switch (index) {
      case 0:
        window.scroll(0, 2000);
        break;
      case 1:
        window.scroll(0, 2169);
        break;
      case 2:
        window.scroll(0, 2329);
        break;
      case 3:
        window.scroll(0, 2489);
        break;
    }
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box backgroundColor="#121D33">
      {windowWidth >= 1200 ? (
        <Box className="wrapper" paddingTop="192px" height="1800px">
          <Flex
            fontWeight="500"
            fontSize="20px"
            lineHeight="32px"
            width="fit-content"
            style={
              scrollPosition > 1782.5 && scrollPosition < 2402
                ? { position: "fixed", top: "30.2px" }
                : scrollPosition > 2402
                ? { marginTop: "auto", marginBottom: "0px", height: "0px" }
                : {}
            }
          >
            <Text color="#008292">{data.title}</Text>
          </Flex>
          <Flex
            justifyContent="space-between"
            backgroundColor="#121D33"
            paddingTop="140px"
          >
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              width="50%"
              height="550px"
              textColor="white"
              style={
                scrollPosition > 1782.5 && scrollPosition < 2402
                  ? { position: "fixed", top: "202.5px" }
                  : scrollPosition > 2402
                  ? { marginTop: "auto", marginBottom: "0px" }
                  : {}
              }
            >
              {data.tab?.map((tab, index) => (
                <Text
                  key={`${index}-big-left-side`}
                  onClick={(e) => handleClickOnLeftSide(tab, index)}
                  textColor="#B4B5B8"
                  className={
                    active == tab.leftSideText
                      ? styles.menuTabActive
                      : styles.menuTab
                  }
                  fontFamily={fonts.DMSansMedium}
                  fontWeight="500"
                  fontSize="56px"
                  lineHeight="64px"
                  letterSpacing="-1.12px"
                >
                  {tab.leftSideText}
                </Text>
              ))}
            </Flex>
            {scrollPosition > 1782.5 && scrollPosition < 2402 && (
              <Flex
                flexDirection="column"
                justifyContent="space-around"
                width="50%"
                height="576px"
              ></Flex>
            )}
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              width="50%"
              maxWidth="631px"
              textColor="white"
              marginLeft="56px"
              marginRight="178px"
              height="1200px"
            >
              {data.tab?.map((tab, index) => (
                <Flex key={`${index}-big-right-side`}>
                  <Text
                    textColor="#B4B5B8"
                    fontFamily={fonts.DMSansMedium}
                    fontWeight="500"
                    fontSize="32px"
                    lineHeight="40px"
                    letterSpacing="-0.64px"
                    className={`${styles.tabDesc} ${
                      active == tab.leftSideText
                        ? styles.menuTabActiveRight
                        : styles.menuTabRight
                    }`}
                  >
                    {tab.rightSideText}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Box>
      ) : (
        <Box
          backgroundColor="#121D33"
          paddingTop="192px"
          height="1800px"
          className={styles.smallWrapper}
        >
          <Flex
            fontWeight="500"
            fontSize="20px"
            lineHeight="32px"
            width="fit-content"
            style={
              scrollPosition > 2100 && scrollPosition < 2860
                ? { position: "fixed", top: "100px" }
                : scrollPosition > 2860
                ? { marginTop: "auto", marginBottom: "0px", height: "0px" }
                : {}
            }
          >
            <Text color="#008292">{data.title}</Text>
          </Flex>
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            width="100%"
            textColor="white"
            height="1200px"
            paddingTop="140px"
          >
            {data.tab?.map((tab, index) => (
              <Flex
                flexDirection="column"
                onClick={(e) => handleClickOnLeftSide(tab, index)}
                key={`${index}-small-flex`}
              >
                <Text
                  key={`${index}-small-left-side`}
                  textColor="#B4B5B8"
                  className={
                    active == tab.leftSideText
                      ? styles.menuTabActive
                      : styles.menuTabSmall
                  }
                  fontFamily={fonts.DMSansMedium}
                  fontWeight="500"
                  fontSize="56px"
                  lineHeight="64px"
                  letterSpacing="-1.12px"
                  paddingBottom="32px"
                >
                  {tab.leftSideText}
                </Text>
                <Text
                  key={`${index}-small-right-side`}
                  textColor="#B4B5B8"
                  fontFamily={fonts.DMSansMedium}
                  fontWeight="500"
                  fontSize="32px"
                  lineHeight="40px"
                  letterSpacing="-0.64px"
                  className={`${styles.tabDesc} ${
                    active == tab.leftSideText
                      ? styles.menuTabActiveRight
                      : styles.menuTabRight
                  }`}
                >
                  {tab.rightSideText}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Box>
      )}
      <Box
        backgroundColor="#121D33"
        className={`${styles.buttonContainer} wrapper`}
      >
        <Link href={"/services"}>
          <a>
            <Button
              variant="outline"
              colorScheme="black"
              className={styles.buttonLink}
              marginTop="12px"
              padding="12px 24px 12px 24px"
              maxWidth="228px"
              textColor="#ffffff"
            >
              {data.button.buttonText}{" "}
              <ArrowForwardIcon fontSize="25px" marginLeft="5px" />
            </Button>
          </a>
        </Link>
      </Box>
    </Box>
  );
};

export default ScrollableTabs;
