import {Box} from '@chakra-ui/react';
import styles from './wysiwyg.module.scss';
interface WysiwygProps {
	post: {
		wysiwyg: string;
	};
}
export const Wysiwyg = ({post}: any) => {
	return (
		<Box
			position='relative'
			overflow='hidden'
			w='100%'
			className={`${styles.wysiwigWrapper} WYSIWYG--default`}>
			<Box
				className={styles.textStyles}
				width='66.67%'
				marginLeft={'auto'}
				marginRight={'auto'}
				dangerouslySetInnerHTML={{__html: post.html}}></Box>
		</Box>
	);
};
