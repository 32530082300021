import dynamic from "next/dynamic";
import styles from "./dropdown.module.scss";
//redux
import { useDispatch } from "react-redux";
import { pushToSelectedFilters } from "../../../redux/slices/filtersSlice";

interface DropdownProps {
  filters: [{ filterName: string }];
  //type for any object
  selectedFilters: string[];
}

export const Dropdown = ({ filters, selectedFilters }: DropdownProps) => {
  const dispatch = useDispatch();
  //prepare all possible filters data for slection
  const filterOptions = filters?.map((filter) => {
    return { value: filter.filterName, label: filter.filterName };
  });

  const allFiltersFilter = filterOptions[0].label;

  let tempSelectedFilters = selectedFilters?.map((filter) => {
    return { value: filter, label: filter };
  });

  const handleSelectChange = (newValue, action) => {
    tempSelectedFilters = newValue;
    dispatch(pushToSelectedFilters(newValue.label));
  };

  // disabling ssr for this component because it throws an error Prop `id` did not match. Server: "react-select-9-live-region" Client: "react-select-2-live-region"
  const Select = dynamic(
    import("chakra-react-select").then((mod) => mod.Select),
    { ssr: false }
  );

  return (
    <>
      <Select
        size="lg"
        className={styles.select}
        options={filterOptions}
        defaultValue={filterOptions[0]}
        selectedOptionStyle="check"
        value={tempSelectedFilters}
        onChange={(newValue, action) => {
          handleSelectChange(newValue, action);
        }}
        isSearchable={false}
        chakraStyles={{
          dropdownIndicator: (provided) => ({
            ...provided,
            bg: "transparent",
            px: 2,
            cursor: "inherit",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
          }),
          control: (provided) => ({
            ...provided,
            _focus: {
              backgroundColor: "#F2F3F5",
            },
          }),

          menuList: (provided) => ({
            ...provided,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderWidth: 0,
            backgroundColor: "white",
          }),
          option: (provided) => ({
            ...provided,
            backgroundColor: "white",
          }),
        }}
      />
    </>
  );
};
