import { Box, Text } from "@chakra-ui/react";
import LineLabel from "../../atoms/labels/label-with-line/line-label";
import styles from "./contact.module.scss";

interface ContactsProps {
  contactsData: {
    email: string;
    phoneNumber: string;
  };
}
const Contacts = ({ contactsData }: ContactsProps) => {
  return (
    <Box>
      <LineLabel
        key={"Contact"}
        fontSize="20px"
        fontWeight="500"
        labelText="Contact"
        color={"white"}
        lineHeight="32px"
      ></LineLabel>
      <Text
        className={styles.text}
        key={contactsData.email}
        cursor={"pointer"}
        fontSize="24px"
        fontWeight="500"
        lineHeight="36px"
        width={"fit-content"}
        marginTop="24px"
        marginBottom="16px"
        color="white"
        onClick={() => {
          window.location.href = `mailto:${contactsData.email}`;
        }}
      >
        {contactsData.email}
      </Text>
      <Box>
        <Text
          key={contactsData.phoneNumber}
          className={styles.text}
          cursor={"pointer"}
          color="white"
          fontSize="24px"
          fontWeight="500"
          lineHeight="36px"
          width={"fit-content"}
          onClick={() => {
            window.open(`tel:${contactsData.phoneNumber.replace("(0)", "")}`); // window.location.href = `tel:${contactsData.email}`;
          }}
        >
          {contactsData.phoneNumber}
        </Text>
      </Box>
    </Box>
  );
};
export default Contacts;
