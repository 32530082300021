import { Box, Flex } from "@chakra-ui/react";
import { StatProps } from "../../../interfaces";
import { StatLabel } from "../../atoms/labels/stat-label/stat-label";
import styles from "./stats-grid.module.scss";
interface StatsGridInterface {
  post: { stats: Array<StatProps> };
}
export const StatsGrid = ({ post }: StatsGridInterface) => {
  return (
    <Flex
      className={`${styles.statsGridWrapper} wrapper`}
      justifyContent={"space-between"}
      gap={"56px"}
    >
      {post.stats?.map((stat: StatProps) => {
        return (
          <Box
            width={"300px"}
            key={stat.description}
            className={styles.statWrapper}
          >
            <StatLabel stat={stat.stat} description={stat.description} />
          </Box>
        );
      })}
    </Flex>
  );
};
