import React from "react";

export const BulbLogo = (props) => {
  return (
    <svg
      width="31"
      height="40"
      viewBox="0 0 31 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5205 37.2603C11.046 37.2603 8.21918 34.4334 8.21918 30.9589C8.21918 27.4844 11.046 24.6575 14.5205 24.6575C17.9951 24.6575 20.8219 27.4844 20.8219 30.9589C20.8219 34.4334 17.9951 37.2603 14.5205 37.2603ZM2.73973 19.4521C2.73973 17.1858 4.58301 15.3425 6.84932 15.3425C9.11562 15.3425 10.9589 17.1858 10.9589 19.4521C10.9589 21.7184 9.11562 23.5616 6.84932 23.5616C4.58301 23.5616 2.73973 21.7184 2.73973 19.4521ZM23.2877 16.4384C25.554 16.4384 27.3973 18.2816 27.3973 20.5479C27.3973 22.8142 25.554 24.6575 23.2877 24.6575C21.0214 24.6575 19.1781 22.8142 19.1781 20.5479C19.1781 18.2816 21.0214 16.4384 23.2877 16.4384ZM30.137 20.5479C30.137 16.771 27.0647 13.6986 23.2877 13.6986C21.7452 13.6986 20.3244 14.2175 19.1781 15.0811V1.36986C19.1781 0.613151 18.5649 0 17.8082 0C17.0515 0 16.4384 0.613151 16.4384 1.36986V22.1271C15.8197 21.9929 15.1786 21.9178 14.5205 21.9178C14.2433 21.9178 13.9699 21.9348 13.6986 21.9595V5.75342C13.6986 4.99671 13.0855 4.38356 12.3288 4.38356C11.5721 4.38356 10.9589 4.99671 10.9589 5.75342V13.9852C9.8126 13.1216 8.39178 12.6027 6.84932 12.6027C3.07233 12.6027 0 15.6751 0 19.4521C0 23.2077 3.04 26.2652 6.78849 26.2981C5.96329 27.6614 5.47945 29.2532 5.47945 30.9589C5.47945 35.9441 9.53534 40 14.5205 40C19.5058 40 23.5616 35.9441 23.5616 30.9589C23.5616 29.6855 23.2937 28.474 22.8164 27.3731C22.9726 27.3841 23.1288 27.3973 23.2877 27.3973C27.0647 27.3973 30.137 24.3249 30.137 20.5479Z"
        fill="#121D33"
        {...props}
      />
    </svg>
  );
};
