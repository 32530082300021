export const wysiwygMapping = (data: any) => {
	if (!data) return null;
	let wysiwyg = '<p> </p>';
// 
	if (process.env.NEXT_PUBLIC_GRAPHQL_URL && process.env.NEXT_PUBLIC_APP_URL) {
		const urlObject = new URL(process.env.NEXT_PUBLIC_GRAPHQL_URL);
		urlObject.protocol = 'https';
		const origin = urlObject.origin;

		// wysiwyg = DOMPurify.sanitize(wysiwyg);
		wysiwyg = data?.replace(/<a([^>]+?)href="([^"]+?)"/g, (match, pre, url) => {
			if (url.startsWith(origin)) {
				url = url.replace(origin, process.env.NEXT_PUBLIC_APP_URL);
			}
			return `<a${pre}href="${url}"`;
		});
	}

	return {
		html: wysiwyg,
	};
};
