export const postHeaderMapping = (post: any) => {
	if (post?.partialbackground) {
		const {
			backgroundtype = null,
			customcolour = null,
			themebackgroundcolor = null,
		} = post.partialbackground;

		let backgroundColor =
			backgroundtype === 'customColor' ? customcolour : themebackgroundcolor;
		if (backgroundtype === null) backgroundColor = null;

		// Create a shallow copy of the post object and add the backgroundColor property
		return {
			...post,
			backgroundColor,
		};
	}

	return post;
};
