/** @format */

import {Flex, Grid, Image, Text} from '@chakra-ui/react';
import styles from './services-cards-grid.module.scss';
import {colors} from '../../../styles/js/colors';

interface ServicesCardGridProps {
	cards: [
		{
			cardLinkTitle: string;
			svg: {
				link: string;
				altText?: string;
			};
			cardLink: {
				url: string;
			};
		},
	];
}
export const ServicesCardGrid = ({cards}: ServicesCardGridProps) => {
	return (
		<Grid className={styles.grid} gridTemplateColumns={'1fr 1fr'} gap={'24px'}>
			{cards?.map(card => {
				return (
					<Flex
						key={card.cardLinkTitle}
						alignItems={'center'}
						padding={'16px'}
						gap={'16px'}
						w={'286px'}
						onClick={() => (window.location.href = card.cardLink?.url)}
						border={`2px solid ${colors.gray[10]}`}
						borderRadius={'8px'}
						_hover={{
							border: `2px solid ${colors.gray[25]} !important`,
							cursor: 'pointer',
						}}>
						<Image
							w='48px'
							h='48px'
							src={card.svg?.link}
							alt={card.svg?.altText}></Image>
						<Text textStyle={{base: 'heading8Medium'}}>
							{card.cardLinkTitle}
						</Text>
					</Flex>
				);
			})}
		</Grid>
	);
};
