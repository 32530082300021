/** @format */

import {Flex, Text, Image} from '@chakra-ui/react';

interface BlogAuthorProps {
	link: string;
	altText: string;
	authorName: string;
	authorJobTitle: string;
}

export const BlogAuthor = ({
	authorName,
	authorJobTitle,
	link,
	altText,
}: BlogAuthorProps) => {
	return (
		<Flex>
			<Image
				src={link}
				alt={altText || 'Author Image'}
				width={'80px'}
				height={'80px'}
				borderRadius={'40px'}></Image>
			<Flex flexDir={'column'} marginLeft={'16px'} justifyContent='center'>
				<Text textStyle={{base: 'heading8Medium'}}>{authorName}</Text>
				<Text>{authorJobTitle}</Text>
			</Flex>
		</Flex>
	);
};
