/** @format */

import {BlogCardProps} from '../classes/blog-card';
import {BlogCardsProps} from '../classes/blog-cards';

interface DataToBeFormatedProjects {
	projects: {
		edges: [
			{
				node: {
					slug: string;
					title: string;
					pageLayout: {
						flexibleContent: [
							{
								cardView: {
									title: string;
									excerpt: string;
									thumbnail: {sourceUrl: string};
								};
								projectsLayout: {category: Array<{name: string}>};
								servicesLayout: {category: Array<{name: string}>};
								newsAndInsightsLayout: {category: Array<{name: string}>};
								date: string;
								heroText: string;
								postType: string;
								heroDescription: string;
								heroImage: {
									link: string;
									altText?: string;
								};
							},
						];
					};
				};
			},
		];
	};
}

export const ProjectCardDataFormater = (
	dataToBeFormated: DataToBeFormatedProjects,
) => {
	if (dataToBeFormated?.projects?.edges) {
		const allProjectCards = new BlogCardsProps();
		dataToBeFormated.projects.edges.forEach(NewsPost => {
			const projectCard = new BlogCardProps();

			projectCard.date = NewsPost.node.pageLayout.flexibleContent[0].date;
			projectCard.heroText =
				NewsPost.node.pageLayout.flexibleContent[0].heroText;
			projectCard.slug = NewsPost.node.slug;
			projectCard.postType =
				NewsPost.node.pageLayout.flexibleContent[0].postType;
			projectCard.heroDescription =
				NewsPost.node.pageLayout.flexibleContent[0].heroDescription;
			projectCard.heroImage.link =
				NewsPost.node.pageLayout.flexibleContent[0]?.heroImage?.link;
			projectCard.heroImage.altText =
				NewsPost.node.pageLayout.flexibleContent[0]?.heroImage?.altText;
			projectCard.cardView =
				NewsPost.node.pageLayout.flexibleContent[0]?.cardView;
			projectCard.newsAndInsightsLayout =
				NewsPost.node.pageLayout.flexibleContent[0]?.newsAndInsightsLayout;
			projectCard.projectsLayout =
				NewsPost.node.pageLayout.flexibleContent[0]?.projectsLayout;
			allProjectCards.blogCards.push(projectCard);
		});

		return allProjectCards;
	}
};
